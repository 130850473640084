<template>
    <div>
        <h2 class="heading-l fw-bold mb-4 mb-sm-3">Overall Analysis</h2>
        <p class="heading-s fw-bold">
            {{ dayjs(filters.fromDateTime).format('DD MMM YYYY') }} - {{ dayjs(filters.toDateTime).format('DD MMM YYYY') }}
        </p>
        <!-- <div class="grid mt-3 mt-sm-0">
            <div class="g-col-6 form-floating">
                <SelectField
                    name="year"
                    label="Year"
                    class="mb-0"
                    :items="filters.years"
                    @update:model-value="onFilter($event, 'year')"
                    item-text="label"
                    item-value="value"
                    :disabled="(!isBusy && analyticsSummary) ? false : true"
                    form-floating
                ></SelectField>
            </div>
            <div class="g-col-6 form-floating">
                <SelectField
                    name="month"
                    label="Month"
                    class="mb-0"
                    :items="filters.months"
                    @update:model-value="onFilter($event, 'month')"
                    item-text="label"
                    item-value="value"
                    :disabled="(!isBusy && analyticsSummary && filters.months.length > 0) ? false : true"
                    form-floating
                ></SelectField>
            </div>
        </div> -->
    </div>
    <div class="grid">
        <!-- ONLY ONE most selected -->
        <!-- <AnalyticsDataCard
            :is-busy="isBusy"
            :loaded="analyticsSummary ? true : false"
            title="Skin Type and Condition Analysis"
            class="g-col-12 g-col-lg-6 g-col-xl-4 card border">
            <h3 class="heading-m fw-bold mb-4">Most selected skin tone, type & condition</h3>
            <div v-if="analyticsSummary">
                <div v-for="stacaRecord in skinTypeAndConditionAnalysisRecords" class="grid">
                    <div 
                        v-for="(asmsRecord, asmsIndex) in stacaRecord.analyticSkinMeasurementSectionRecords" 
                        class="d-flex justify-content-between gap-3 g-col-12">
                        <div class="d-flex w-100">
                            <span class="chart-legend-color-box" :style="{ backgroundColor: AppConstants.CHART_COLOURS[asmsIndex]}"></span>
                            <div class="ms-2 w-100">
                                <p class="mb-0">{{ asmsRecord.description }}</p>
                                <div class="">
                                    <p class="mb-0 fw-bold">
                                        <span class="me-2">
                                            {{ 
                                                getMostSelectedSkinMeasurementValue(asmsRecord.analyticSkinMeasurementValueRecords)?.count === 0 
                                                ? 'No data found' 
                                                : getMostSelectedSkinMeasurementValue(asmsRecord.analyticSkinMeasurementValueRecords)?.skinMeasurementValueName 
                                            }}
                                        </span>
                                        <span v-if="getMostSelectedSkinMeasurementValue(asmsRecord.analyticSkinMeasurementValueRecords)?.count !== undefined && getMostSelectedSkinMeasurementValue(asmsRecord.analyticSkinMeasurementValueRecords)?.count !== null" class="badge bg-info bg-opacity-10 text-dark border analytics-count-badge">
                                            {{ formatNumberWithCommas(getMostSelectedSkinMeasurementValue(asmsRecord.analyticSkinMeasurementValueRecords)?.count ?? 0) }}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <span class="align-self-center body-s badge bg-dark bg-opacity-10 text-dark">
                            {{ getMostSelectedSkinMeasurementValuePercentage(asmsRecord.analyticSkinMeasurementValueRecords, inventorySummaryAnalysisRecord?.totalConsultations ?? 0) }}
                        </span>
                    </div>
                </div>
            </div>
        </AnalyticsDataCard> -->

        <!-- show MULTIPLE most selected -->
        <AnalyticsDataCard
            :is-busy="isBusy"
            :loaded="analyticsSummary ? true : false"
            title="Skin Type and Condition Analysis"
            class="g-col-12 g-col-lg-6 g-col-xl-4 card border">
            <h3 class="heading-m fw-bold mb-4">Most selected skin tone, type & condition</h3>
            <div v-if="analyticsSummary">
                <div v-for="stacaRecord in skinTypeAndConditionAnalysisRecords" class="grid">
                    <div v-for="(asmsRecord, asmsIndex) in stacaRecord.analyticSkinMeasurementSectionRecords" class="gap-3 g-col-12">
                        <!-- <div v-if="getAllMostSelectedSkinMeasurementValues(asmsRecord.analyticSkinMeasurementValueRecords).length === 1" class="d-flex justify-content-between"> -->
                        <div v-if="getAllMostSelectedSkinMeasurementValues(asmsRecord.analyticSkinMeasurementValueRecords).length > 0" class="d-flex justify-content-between">
                            <div class="d-flex w-100">
                                <span class="chart-legend-color-box" :style="{ backgroundColor: AppConstants.CHART_COLOURS[asmsIndex]}"></span>
                                <div class="ms-2 w-100">
                                    <p class="mb-0">{{ asmsRecord.description }}</p>
                                    
                                    <div v-for="item in getAllMostSelectedSkinMeasurementValues(asmsRecord.analyticSkinMeasurementValueRecords)">
                                        <p class="mb-0 fw-bold">
                                            <span class="me-2">
                                                {{ item?.count === 0 ? 'No data found' : item?.skinMeasurementValueName }}</span>
                                            <span 
                                                class="badge bg-info bg-opacity-10 text-dark border analytics-count-badge">
                                                {{ formatNumberWithCommas(item.count ?? 0) }}</span>
                                                <!-- v-if="getAllMostSelectedSkinMeasurementValues(asmsRecord.analyticSkinMeasurementValueRecords).length === 1"  -->
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <span class="align-self-center body-s badge bg-dark bg-opacity-10 text-dark">
                                <!-- {{ getAllMostSelectedSkinMeasurementValues(asmsRecord.analyticSkinMeasurementValueRecords).length > 1 ? 'All ' : '' }} -->
                                {{ getMostSelectedSkinMeasurementValuePercentage(asmsRecord.analyticSkinMeasurementValueRecords, inventorySummaryAnalysisRecord?.totalConsultations ?? 0) }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </AnalyticsDataCard>
        <AnalyticsDataCard
            :is-busy="isBusy"
            :loaded="analyticsSummary ? true : false"
            class="g-col-12 g-col-md-6 g-col-xl-4 card border">
            <h3 class="heading-m fw-bold mb-4">Top 5 Ingredients</h3>
            <div v-if="currentRangeIngredientRecordFrequencyRecords">
                <template v-if="currentRangeIngredientRecordFrequencyRecords.length">
                    <AnalyticsPercentageBar 
                        v-for="(record, index) in currentRangeIngredientRecordFrequencyRecords"
                        :key="index"
                        :percentage="getPercentages(currentRangeIngredientRecordFrequencyRecords.map(x => x.count), record.count, inventorySummaryAnalysisRecord?.totalConsultations)"
                        :label="record.object.displayName"
                        :count="record.count"
                        bg-color="#606c38"
                    ></AnalyticsPercentageBar>
                </template>
                <p v-else class="mb-0">No data found.</p>
            </div>
        </AnalyticsDataCard>
        <AnalyticsDataCard
            :is-busy="isBusy"
            :loaded="analyticsSummary ? true : false"
            class="g-col-12 g-col-md-6 g-col-xl-4 card border">
            <h3 class="heading-m fw-bold mb-4">Top 5 Products</h3>
            <div v-if="currentRangeProductRecordFrequencyRecords">
                <template v-if="currentRangeProductRecordFrequencyRecords.length">
                    <AnalyticsPercentageBar 
                        v-for="(record, index) in currentRangeProductRecordFrequencyRecords"
                        :key="index"
                        :percentage="getPercentages(currentRangeProductRecordFrequencyRecords.map(x => x.count), record.count, inventorySummaryAnalysisRecord?.totalConsultations)"
                        :label="record.productRecord.displayName"
                        :count="record.count"
                        bg-color="#457b9d"
                    ></AnalyticsPercentageBar>
                </template>
                <p v-else class="mb-0">No data found.</p>
            </div>
        </AnalyticsDataCard>
        <AnalyticsDataCard
            :is-busy="isBusy"
            :loaded="analyticsSummary ? true : false"
            class="g-col-12 g-col-md-6 g-col-xl-4 card border">
            <h3 class="heading-m fw-bold mb-4">Top 5 Countries</h3>
            <div v-if="clientCountrySummaryAnalysisRecord">
                <template v-if="top5ClientCountryFrequencyRecords">
                    <AnalyticsPercentageBar 
                        v-for="(record, index) in top5ClientCountryFrequencyRecords"
                        :key="index"
                        :percentage="getPercentages(top5ClientCountryFrequencyRecords.map(x => x.count), record.count, clientCountrySummaryAnalysisRecord?.totalClient)"
                        :label="record.country.name"
                        :count="record.count"
                        bg-color="#457b9d"
                    >
                        <template #label-start>
                            <VLazyImage v-if="getCountry(record)"
                                :src="getCountry(record)?.flag?.url ?? ''"
                                :alt="getCountry(record)?.flag?.name"
                                class="analytics-summary-countries-country-flag img-fluid me-2"
                            ></VLazyImage>
                        </template>
                    </AnalyticsPercentageBar>
                </template>
                <p v-else class="mb-0">No data found.</p>
            </div>
        </AnalyticsDataCard>

        <div class="g-col-12 g-col-xl-4 card border">
            <AnalyticsDataCard
                :is-busy="isBusy"
                :loaded="analyticsSummary ? true : false"
                icon-name="people-filled"
                :percentage="clientSummaryAnalysisRecord && clientSummaryAnalysisRecord.quantityGrowthPercentage !== null ? clientSummaryAnalysisRecord.quantityGrowthPercentage : 0"
                :title="`No. of ${customerTerminology?.uppercasePlural}`"
                :value="clientSummaryAnalysisRecord 
                ? `${formatNumberWithCommas(clientSummaryAnalysisRecord.currentRangeQuantity)} ${customerTerminology?.lowercase}${clientSummaryAnalysisRecord.currentRangeQuantity > 1 ? 's' : ''}`
                : 'Unavailable'"
                :comparing-duration="dayjs(filters.toDateTime).diff(dayjs(filters.fromDateTime), 'days')"
            ></AnalyticsDataCard>

            <AnalyticsDataCard
                :is-busy="isBusy"
                :loaded="!!skinTypeAndConditionAnalysisRecords"
                icon-name="paper"
                title="No. of Consultations"
                :value="skinTypeAndConditionAnalysisRecords ? `${skinTypeAndConditionAnalysisRecords[0].totalConsultations} consultation${skinTypeAndConditionAnalysisRecords[0].totalConsultations > 1 ? 's' : ''}` : 'Unavailable' || 'Unavailable'"
            ></AnalyticsDataCard>
        </div>

        <AnalyticsDataCard
            :is-busy="isBusy"
            :loaded="analyticsSummary ? true : false"
            icon-name="inventory-filled"
            :percentage="inventorySummaryAnalysisRecord && inventorySummaryAnalysisRecord.productQuantityGrowthPercentage !== null ? inventorySummaryAnalysisRecord.productQuantityGrowthPercentage : 0"
            title="Inventory"
            :value="inventorySummaryAnalysisRecord ? `${inventorySummaryAnalysisRecord.currentRangeProductQuantity} item${inventorySummaryAnalysisRecord.currentRangeProductQuantity > 1 ? 's' : ''} sold` : 'Unavailable'"
            :comparing-duration="dayjs(filters.toDateTime).diff(dayjs(filters.fromDateTime), 'days')"
            class="g-col-12 g-col-xl-4 card border"
        ></AnalyticsDataCard>
        <AnalyticsDataCard
            :is-busy="isBusy"
            :loaded="analyticsSummary ? true : false"
            icon-name="dollar-filled"
            :percentage="inventorySummaryAnalysisRecord && inventorySummaryAnalysisRecord.productRevenueGrowthPercentage !== null ? inventorySummaryAnalysisRecord.productRevenueGrowthPercentage : 0"
            title="Revenue"
            :value="inventorySummaryAnalysisRecord
            ? `${portalCurrency?.symbol} ${formatNumberWithCommas(parseInt(inventorySummaryAnalysisRecord.currentRangeProductRevenue.toFixed(2)))}` : 'Unavailable'"
            :comparing-duration="dayjs(filters.toDateTime).diff(dayjs(filters.fromDateTime), 'days')"
            class="g-col-12 g-col-xl-4 card border"
        ></AnalyticsDataCard>
    </div>
</template>

<script lang="ts" setup>
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { useAnalyticsSummaryStore, useAnalyticsStore, useCommonDataStore, useOrganisationStore } from '@/modules/proportal/store';
import AppConstants from '@/modules/proportal/app.constants';
import { formatNumberWithCommas } from '@/modules/core/utilities';
import { AnalyticsDataCard, AnalyticsPercentageBar } from './';
import { VLazyImage } from '@/modules/core/components';
import { ClientCountryFrequencyRecord, Country } from '../../types';

const analyticsSummaryStore = useAnalyticsSummaryStore();
const { 
    filters,
    isBusy, 
    analyticsSummary,
    skinTypeAndConditionAnalysisRecords,
    clientSummaryAnalysisRecord,
    inventorySummaryAnalysisRecord,
    clientCountrySummaryAnalysisRecord,
    currentRangeIngredientRecordFrequencyRecords,
    currentRangeProductRecordFrequencyRecords,
    clientCountryFrequencyRecords,
    top5ClientCountryFrequencyRecords,
    customerTerminology,
    getAllMostSelectedSkinMeasurementValues,
    getMostSelectedSkinMeasurementValue,
    getMostSelectedSkinMeasurementValuePercentage
} = storeToRefs(analyticsSummaryStore);

const analyticsStore = useAnalyticsStore();
const { getPercentages } = storeToRefs(analyticsStore);

const commonDataStore = useCommonDataStore();
const { countries } = storeToRefs(commonDataStore);

const organisationStore = useOrganisationStore();
const { portalCurrency } = storeToRefs(organisationStore); 

const getCountry = (clientCountryFrequencyRecord: ClientCountryFrequencyRecord): Country|undefined => {
    if (!clientCountryFrequencyRecord) return;

    if (clientCountryFrequencyRecord.country.flag?.url) {
        return clientCountryFrequencyRecord.country;
    }

    const fallbackCountry = countries.value.find(x => x.key === clientCountryFrequencyRecord.country.key);
    if (fallbackCountry) {
        return fallbackCountry;
    }
}

analyticsSummaryStore.load();
</script>
<style lang="scss" scoped>

.analytics-skin-cta {
    --bs-btn-bg: #{rgba($secondary, 0.1)};
    --bs-btn-border-color: #{rgba($secondary, 0.1)};
    --bs-btn-hover-bg: #{rgba($secondary, 0.3)};
    --bs-btn-active-bg: #{rgba($secondary, 0.1)};
    color: $black;
}

.analytics-summary-countries-country-flag {
    outline: 1px solid $gray-400;
    width: 3rem;
    aspect-ratio: 2 / 1;
    object-fit: fill;
}

.plus {
    font-size: 30px;
}
</style>