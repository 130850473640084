<template>
    <slot :isSidebarHidden="isSidebarHidden" :hasAnySubscription="hasAnySubscription"></slot>
</template>

<script lang="ts" setup>
import { useNavigationStore, useOrganisationStore } from '@/modules/proportal/store';
import { storeToRefs } from 'pinia';

interface Props {
    isSidebarHidden?: boolean;
}

defineProps<Props>();

const navigationStore = useNavigationStore();
const organisationStore = useOrganisationStore();
const { hasAnySubscription } = storeToRefs(organisationStore);
// const { isSidebarHidden } = storeToRefs(navigationStore);
</script>