<!--
    The app does not contain any template.
    It's template would vary base on the html rendered server side.
-->

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { setCssHelperVars } from '@/modules/core/utilities';
import { usePortalAuthStore } from '@/modules/proportal/store';
import { 
    LoginPage,
    EnterpriseRegistrationPage,
    SubscriberRegistrationPage,    
    ResetPasswordRequestPage,
    CompletePasswordChangePage,
    PortalHome,
    PortalConsultation,
    PortalClientsPage,
    PortalEducationPage,
    PortalSettingsPage,
    RouterBackButton,
    PortalConsultationPage,
    NavigationAuthButtonMenu,
    CommonDataProvider,
    PortalPageUrlsProvider,
    PortalSchedulingPage,
    MainNavigationMobileMoreButton,
    PortalAnalyticsPage,
    UpcomingAppointmentsListing,
    PricingTablePublic,
    DesktopNavigation,
    HelpSupportApp
} from '@/modules/proportal/components'

import {
    Icon,
    Avatar,
    ToastContainer,
    Alert
} from '@/modules/core/components';

export default defineComponent({
    components: {
        MainNavigationMobileMoreButton,
        LoginPage,
        EnterpriseRegistrationPage,
        SubscriberRegistrationPage,
        ResetPasswordRequestPage,
        CompletePasswordChangePage,
        PortalHome,
        Icon,
        Avatar,
        Alert,
        PortalConsultation,
        PortalClientsPage,
        PortalEducationPage,
        PortalSettingsPage,
        RouterBackButton,
        PortalConsultationPage,
        NavigationAuthButtonMenu,
        CommonDataProvider,
        PortalPageUrlsProvider,
        ToastContainer,
        PortalSchedulingPage,
        PortalAnalyticsPage,
        UpcomingAppointmentsListing,
        PricingTablePublic,
        DesktopNavigation,
        HelpSupportApp
    },
    setup () {
        const router = useRouter();
        router.beforeResolve(async() => {
            await usePortalAuthStore().getCurrentUser();
        })
        onMounted(() => {
            setCssHelperVars();
            document.body.classList.remove('is-loading');
        })
    }
})
</script>