<template>
    <div class="hstack gap-2 mb-4">
        <Icon symbol="paper" size='l' class="text-secondary"></Icon>
        <span class="heading-l fw-bold text-gray-700">Registration</span>
    </div>
    <form @submit="onSubmit" class="consultant-registration-form">       
        <h5 class="fw-bold mb-4">About You</h5>
        <div class="grid gap-4 mb-5">
            <TextField
                name="Name"
                label="Full Name"
                class="g-col-12 g-col-sm-6 mb-0"
                :disabled="isBusy"
                mandatory
            ></TextField>
            <PhoneNumberField
                name="PhoneNumber"
                label="Phone Number"
                class="g-col-12 g-col-sm-6 mb-0"
                :disabled="isBusy"
                mandatory
                :preferredCountries="['MY', 'US']"
            ></PhoneNumberField>
            <SelectField
                name="CountryId"
                item-value="key"
                item-text="name"
                label="Country/Region"
                placeholder="Select Country/Region"
                :items="countries"
                class="g-col-12 g-col-sm-6 mb-0"
                :disabled="isBusy"
                mandatory
            ></SelectField>
            <TextField
                name="Location"
                label="Location"
                class="g-col-12 g-col-sm-6 mb-0"
                :disabled="isBusy"
                mandatory
            ></TextField>
        </div>
        <h5 class="fw-bold mb-4">Your Organisation</h5>
        <div class="grid gap-4 mb-5">
            <TextField
                name="BusinessName"
                label="Organisation Name"
                class="g-col-12 g-col-sm-6 mb-0"
                :disabled="isBusy"
                mandatory
            ></TextField>
            <TextField
                name="BusinessWebsiteUrl"
                label="Organisation Website"
                class="g-col-12 g-col-sm-6 mb-0"
                placeholder="E.g. https://johndoe.com"
                :disabled="isBusy"
            ></TextField>
            <div class="g-col-12">
                <CheckboxField
                    name="UserHasSkinMasterclassCertificationNumber"
                    label="I have a Skin Masterclass Certification Number"
                    :value="true"
                    :unchecked-value="false"
                    class="mb-3"
                ></CheckboxField>
                <TextField
                    name="SkinMasterclassCertificationNumber"
                    label="Skin Masterclass Certification Number"
                    class="mb-0"
                    mandatory
                    :disabled="isBusy || !values.UserHasSkinMasterclassCertificationNumber"
                ></TextField>
            </div>
        </div>
        <div class="vstack gap-4">
            <button type="submit" class="btn btn-primary w-100 py-3 fw-semibold d-block">Next</button>
            <p v-if="errorMessage" class="text-danger text-center mb-0">{{ errorMessage }}</p>
            <div class="d-flex justify-content-center">
                <slot name="sign-in-offer"></slot>
            </div>
        </div>
    </form>
</template>

<script lang="ts" setup>
import {
    Icon,
    TextField, 
    PhoneNumberField, 
    SelectField, 
    CheckboxField
} from '@/modules/core/components';
import { useForm } from 'vee-validate';
import { enterpriseConsultantRegistrationFormFieldsSchema } from '@/modules/proportal/components/auth/schema';
import { EnterpriseConsultantRegistrationFormFields } from '@/modules/proportal/components/auth/types';
import { ref } from 'vue';
import { getErrorMessage } from '@/modules/core/utilities';
import { useCommonDataStore } from '@/modules/proportal/store';
import { storeToRefs } from 'pinia';

const emit = defineEmits<{
    (e: 'done', data: EnterpriseConsultantRegistrationFormFields): void
}>()

const { handleSubmit, values } = useForm<EnterpriseConsultantRegistrationFormFields>({
    validationSchema: enterpriseConsultantRegistrationFormFieldsSchema
});

const { countries } = storeToRefs(useCommonDataStore());

const errorMessage = ref('');
const isBusy = ref(false);

const onSubmit = handleSubmit(
    async (values) => {
        isBusy.value = true;
        try {
            emit('done', values)
        } catch (error) {
            errorMessage.value = getErrorMessage(error)
        } finally {
            isBusy.value = false;
        }
    },
    () => {
        const invalidField = document.querySelector('.is-invalid') as HTMLInputElement|HTMLSelectElement;
        if (invalidField) {
            invalidField.focus();
            window.scrollTo(0, invalidField.offsetTop - 72);
        }
    }
)
</script>