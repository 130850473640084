import { ref } from 'vue';

export const useCountdownTimer = (secondsParam: number) => {
    const seconds = ref(secondsParam);
    const isRunning = ref(false);
    const timer = ref<ReturnType<typeof setTimeout>|null>(null);

    const start = () => {
        seconds.value = secondsParam;
        if (!isRunning.value) {
            isRunning.value = true;
            timer.value = setInterval(() => {
                seconds.value--;
                if (seconds.value === 0) {
                    clearInterval(timer.value!);
                    isRunning.value = false;
                }
            }, 1000);
        }
    }
      
    const pause = () => {
        if (isRunning.value) {
            clearInterval(timer.value!);
            isRunning.value = false;
        }
    }
      
    const stop = () => {
        if (isRunning.value) {
            clearInterval(timer.value!);
            seconds.value = secondsParam;
            isRunning.value = false;
        }
    };
      
    const restart = () => {
        stop();
        start();
    }

    return {
        start, pause, stop, restart, seconds, isRunning
    }
}