// These are here so that the bundler picks them up and processes them
import "@/modules/core/scss/index.scss";
import "@/modules/proportal/scss/index.scss";

// @ts-ignore
import 'virtual:svg-icons-register';

import { createApp } from 'vue';
import pinia from './store/index';
import configureAxios from "@/modules/proportal/config/axios";
import App from './proportal.vue';
import { router } from "./router";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/browser";

const app = createApp(App);

if (import.meta.env.MODE !== 'development') {
    Sentry.init({
        app,
        dsn: "https://d63a3709dabc4d1a8970b2af8c35f0d9@o4504790555623424.ingest.sentry.io/4504903699464192",
        environment: import.meta.env.MODE,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: ["localhost", "https://digitalconsult.skinmasterclass.com", "dev.skinmaster.bitlab.services", /^\//],
            }),
            new Sentry.Replay(),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

app.use(pinia); 
app.use(router)

configureAxios();

app.mount('#app');
