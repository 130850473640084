<template>
    <GenericModal
        ref="modalRef"
        modal-title="Verify your Email"
        @shown="sendOtp">        
        <form
            @submit.prevent="onSubmit"
            class="px-4 pb-4 d-flex flex-column"
            novalidate>
            <p class="text-lg">
                We've sent a verification code to <span class="text-muted fw-bold">{{ email }}</span>
            </p>

            <TextField
                :name="name"
                :maxlength="6"
                :minlength="6"
                autocomplete="one-time-code"
                inputmode="numeric"
                placeholder="Enter verification code"
                hide-max-length-hint
            ></TextField>

            <div class="mb-4 d-flex">
                <p v-if="!canSendOtp" class="mb-0">Did not receive the OTP? Resend in {{ timeLeftLabel }} </p>
    
                <button v-else class="btn btn-link p-0 d-inline-block link-info" type="button">Resend</button>
            </div>

            <button
                class="btn btn-dark"
                :disabled="!isValid">
                Submit
            </button>
        </form>
    </GenericModal>
</template>

<script setup lang="ts">
import { GenericModal, TextField } from '@/modules/core/components';
import { useIsFieldValid } from 'vee-validate';
import { ref, toRef } from 'vue';
import { useConsultantOtp } from './composable';

interface Props {
    email?: string;
    name: string;
}

const props = defineProps<Props>()

const emit = defineEmits<{
    (e: 'submit'): void
}>()

const isValid = useIsFieldValid(toRef(props, 'name'))

const {
    timeLeftLabel,
    canSendOtp,
    sendOtp: createSendOtpRequest
} = useConsultantOtp(toRef(props, 'email', ''), 1);

const onSubmit = () => {
    if (!isValid.value) return;
    emit('submit')
    modalRef.value?.hide()
}

const sendOtp = async () => {
    if (!props.email) return;

    createSendOtpRequest()
}

const modalRef = ref<InstanceType<typeof GenericModal>>();

defineExpose({
    show: () => modalRef.value?.show()
})

</script>

<style scoped>

</style>