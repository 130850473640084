<template>
    <div v-if="auth.accessToken && auth.consultant">
        <a v-if="!mdAndGreater" :href="buttonUrl" @click="onClick">
            <Avatar
                :text="auth.consultant.name"
                :image-url="auth.consultant.profilePicture?.url"
            ></Avatar>
        </a>
        <Dropdown v-else popper-class="popper-auth" placement="bottom-end">
            <a :href="buttonUrl" @click="onClick">
                <Avatar
                    :text="auth.consultant.name"
                    :image-url="auth.consultant.profilePicture?.url"
                ></Avatar>
            </a>
            <template #popper>
                <div class="p-3 d-flex flex-column">
                    <Avatar 
                        class="mb-3 mx-auto"
                        size="xl"
                        :text="auth.consultant.name"
                        :image-url="auth.consultant.profilePicture?.url"
                    ></Avatar>
                    <h2 class="heading-m fw-bold mb-0 text-center">{{ auth.consultant.name }}</h2>
                    <p class="subheading text-muted text-center">{{ auth.consultant.email }}</p>

                    <slot></slot>

                    <div class="d-grid">
                        <button
                            @click="portalAuthStore.logout(false, true, false)"
                            class="btn btn-overlay-secondary has-initial-bg"
                            type="button">
                            Sign Out
                        </button>
                    </div>
                </div>
            </template>
        </Dropdown>
    </div>
</template>

<script lang="ts" setup>
import { Avatar } from '@/modules/core/components';
import { usePortalAuthStore } from '@/modules/proportal/store/auth';
import { breakpointsBootstrapV5, useBreakpoints } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { Dropdown } from 'floating-vue';

import 'floating-vue/dist/style.css';

defineProps({
    buttonUrl: String
})

const portalAuthStore = usePortalAuthStore();
const { auth } = storeToRefs(portalAuthStore)

const breakpoints = useBreakpoints(breakpointsBootstrapV5);
const mdAndGreater = breakpoints.greater('md');

const onClick = (e: Event) => {
    if (mdAndGreater.value) return e.preventDefault();
}
</script>

<style lang="scss">

.popper-auth {
    min-width: 300px;

    .v-popper__arrow-container {
        display: none;
    }

    .v-popper__inner {
        box-shadow: 0 6px 8px #0000001a;
    }
}
</style>