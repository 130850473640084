<template>
    <FadeInOut mode="out-in" :duration="300">
        <div v-if="isBusy" class="row">
            <div class="col-lg-4 col-xl-3 col-xxl-2 mb-5 mb-lg-0">
                <ListPlaceholder
                    :count="4"
                    :size="4"
                ></ListPlaceholder>
            </div>
            <div class="col-lg-8 col-xl-9 col-xxl-10">
                <ListPlaceholder
                    :count="4"
                    :size="5"
                ></ListPlaceholder>
            </div>
        </div>
        <div v-else-if="!isBusy && appointmentDateGroupRecords.length > 0">
            <slot name="header"></slot>
            <div class="row">
                <div class="col-lg-4 col-xl-3 col-xxl-2 mb-5 mb-lg-0">
                    <div class="nav flex-nowrap flex-lg-column nav-pills gap-2"
                        id="tabNav-scheduling"
                        role="tablist"
                        aria-orientation="vertical">
                        <button
                            v-for="(item, index) of appointmentDateGroupRecords"
                            @click="activeDateGroup = item.date"
                            class="nav-link flex-shrink-0"
                            :class="{ active: item.date === activeDateGroup }"
                            type="button"
                            role="tab"
                            :aria-controls="`tab-${index}`"
                            :id="`tab-${index}`"
                            :aria-selected="(item.date === activeDateGroup) ? 'true' : 'false'">
                            <span class="d-block text-start fw-light">{{ getDateLabel(item) }}</span>
                            <span class="d-block text-start fw-bold">{{ item.date }}</span>
                        </button>
                    </div>
                </div>
                <div class="col-lg-8 col-xl-9 col-xxl-10">
                    <div class="tab-content" id="tabContent-scheduling">
                        <div
                            v-for="(item, index) of appointmentDateGroupRecords"
                            class="tab-pane fade"
                            :class="{ 'active show': item.date === activeDateGroup }"
                            :id="`tabPane-${index}`"
                            role="tabpanel"
                            :aria-labelledby="`tab-${index}`"
                            tabindex="0">
                            <div v-for="record in item.appointmentRecords" :key="record.appointment.id" class="row border-bottom pb-4 mb-4">
                                <div class="col-md-4">
                                    <h5 class="fw-bolder">{{ getTimeRangeLabel(record.appointment.startDateTime, record.appointment.endDateTime) }}</h5>
                                    <p class="fs-m">{{ getShortDateOrdinal(record.appointment.startDateTime) }}</p>
                                </div>
                                <div class="col-md-8">
                                    <h5 class="fw-bolder mb-4">{{ record.appointment.title }}</h5>
                                    <div class="subheading text-muted" v-html="record.appointment.notes"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="py-5">
            <p class="display-l text-primary">Start a consultation with your clients today.</p>
        </div>
    </FadeInOut>
</template>

<script lang="ts" setup>
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useAppointmentsStore } from '../../store';
import { AppointmentDateGroupRecord } from '../../types';
import { FadeInOut } from 'vue3-transitions';
import { ListPlaceholder } from '@/modules/core/components';

dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);

const appointmentsStore = useAppointmentsStore();
const { appointmentDateGroupRecords } = storeToRefs(appointmentsStore);

const today = dayjs().startOf('day');
const fromNow7Days = today.add(14, 'day');

const isBusy = ref(true);
const activeDateGroup = ref('');

const getDateLabel = (group: AppointmentDateGroupRecord) => {
    const date = dayjs(group.date, 'DD MMMM YYYY');
    if (date.isSame(today, 'date')) return 'Today';
    if (date.isSame(today.add(1, 'day'))) return 'Tomorrow';
    return date.fromNow()
}

const getTimeRangeLabel = (startDateTime: string, endDateTime: string) => {
    const start = dayjs(startDateTime);
    const end = dayjs(endDateTime);
    return start.format('h:mm A') + ' - ' + end.format('h:mm A')
}

const getShortDateOrdinal = (date: string) => {
    return dayjs(date).format('MMMM Do, YYYY')
}

appointmentsStore
    .load(today.toDate(), fromNow7Days.toDate())
    .then(() => {
        if (appointmentDateGroupRecords.value.length > 0) {
            activeDateGroup.value = appointmentDateGroupRecords.value[0].date
        }
    })
    .finally(() => isBusy.value = false);

</script>

<style lang="scss">

#tabNav-scheduling {
    overflow-x: auto;
}
</style>