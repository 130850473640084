<template>
    <div class="layout-main-content-inner">
        <RouterView v-slot="{ Component }">
            <component :is="Component">
                <template #home-header>
                    <slot name="home-header"></slot>
                </template>
                <template #restricted-section-popup>
                    <slot name="restricted-section-popup"></slot>
                </template>
            </component>
        </RouterView>
    </div>
</template>

<script lang="ts" setup>
import { router } from '@/modules/proportal/router';

router
    .isReady()
    .then(() => {
        if (router.currentRoute.value.matched.length === 0) {
            router.replace({ name: 'education-home' })
        }
    })

</script>