
<template>
    <h2 class="heading-l fw-bold mb-4 mb-lg-3">{{ customerTerminology?.uppercasePlural }} Analysis Report</h2>
    <p class="heading-s fw-bold">
        {{ dayjs(filters.fromDateTime).format('DD MMM YYYY') }} - {{ dayjs(filters.toDateTime).format('DD MMM YYYY') }}
    </p>
    <div class="grid">
        <AnalyticsDataCard
            :is-busy="isBusy"
            :loaded="analyticsClientsRecord ? true : false"
            class="g-col-12 g-col-lg-6 g-col-xl-4 card border">
            <h3 class="fw-bold heading-m">Gender</h3>
            <div v-if="clientGenderAnalysisRecords">
                <div class="pt-2">
                    <template v-if="clientGenderAnalysisRecords.length > 0">
                        <AnalyticsPercentageBar 
                            v-for="(record, index) in clientGenderAnalysisRecords"
                            :key="index"
                            :percentage="getPercentages(clientGenderAnalysisRecords.map(x => x.count), record.count)"
                            :label="getGenderByKey(record.genderId)?.name ?? 'Gender'"
                            :count="record.count"
                            bg-color="#606c38"
                        ></AnalyticsPercentageBar>
                    </template>
                    <p v-else class="mb-0">No data found.</p>
                </div>
            </div>
            <p v-else class="mb-0">No data found.</p>
        </AnalyticsDataCard>
        <AnalyticsDataCard
            :is-busy="isBusy"
            :loaded="analyticsClientsRecord ? true : false"
            class="g-col-12 g-col-lg-6 g-col-xl-4 card border">
            <h3 class="fw-bold heading-m">Age Group</h3>
            <div v-if="clientAgeAnalysisRecords">
                <div class="pt-2">
                    <template v-if="clientAgeAnalysisRecords.length > 0">
                        <AnalyticsPercentageBar 
                            v-for="(record, index) in clientAgeAnalysisRecords"
                            :key="index"
                            :percentage="getPercentages(clientAgeAnalysisRecords.map(x => x.count), record.count)"
                            :label="`${record.startAge} - ${record.endAge}`"
                            :count="record.count"
                            bg-color="#457b9d"
                        ></AnalyticsPercentageBar>
                    </template>
                    <p v-else class="mb-0">No data found.</p>
                </div>
            </div>
            <p v-else class="mb-0">No data found.</p>
        </AnalyticsDataCard>
        <AnalyticsDataCard
            :is-busy="isBusy"
            :loaded="analyticsClientsRecord ? true : false"
            class="g-col-12 g-col-lg-6 g-col-xl-4 card border">
            <h3 class="fw-bold heading-m">{{ customerTerminology?.uppercase }}'s Activity</h3>
            <div v-if="clientActivityAnalysisRecords">
                <div class="pt-2">
                    <template v-if="clientActivityAnalysisRecords.length > 0">
                    <AnalyticsPercentageBar 
                        v-for="(record, index) in clientActivityAnalysisRecords"
                        :key="index"
                        :percentage="getPercentages(clientActivityAnalysisRecords.map(x => x.count), record.count)"
                        :label="record.range"
                        :count="record.count"
                    ></AnalyticsPercentageBar>
                    </template>
                    <p v-else class="mb-0">No data found.</p>
                </div>
            </div>
            <p v-else class="mb-0">No data found.</p>
        </AnalyticsDataCard>
    </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useAnalyticsStore, useAnalyticsClientsStore, useCommonDataStore } from '@/modules/proportal/store';
import { AnalyticsDataCard, AnalyticsPercentageBar } from './';
import dayjs from 'dayjs';

const commonDataStore = useCommonDataStore();
const { getGenderByKey } = storeToRefs(commonDataStore);
const analyticsClientsStore = useAnalyticsClientsStore();
const { 
    isBusy,
    filters,
    analyticsClientsRecord,
    clientGenderAnalysisRecords,
    clientAgeAnalysisRecords,
    clientActivityAnalysisRecords,
    clientAcquisitionAnalysisRecords,
    customerTerminology,
} = storeToRefs(analyticsClientsStore);

const analyticsStore = useAnalyticsStore();
const { getPercentages } = storeToRefs(analyticsStore);

analyticsClientsStore.load();
</script>

