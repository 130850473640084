<template>
    <div class="row align-items-center justify-content-between mb-4 mb-lg-3">
        <div class="col-lg-6">
            <h2 class="heading-l fw-bold">Skin Type And Condition Analysis Report</h2>
        </div>
        <!-- <div class="col-lg-6 mt-3 mt-lg-0">
            <div class="row justify-content-end">
                <div class="col-sm-6 form-floating">
                    <DatePickerField
                        class="mb-0"
                        name="dateRange"
                        label="Date range"
                        :datePickerConfig="{
                            format: 'dd/MM/yyyy',
                            autoApply: true,
                            range: true,
                            multiCalendars: true,
                            ignoreTimeValidation: false,
                            enableTimePicker: false
                        }"
                        @update:model-value="onFilter"
                        :disabled="(!isBusy && skinTypeAndConditionAnalysisRecords) ? false : true"
                        form-floating
                    ></DatePickerField>
                </div>
            </div>
            <div class="g-col-12 g-col-sm-6">
                <SelectField
                    name="timeInterval"
                    label="Time Interval"
                    class="mb-0"
                    :items="AppConstants.ANALYTICS_TIME_INTERVALS"
                    item-text="label"
                    item-value="value"
                    @update:model-value="onFilter"
                    :disabled="(!isBusy && skinTypeAndConditionAnalysisRecords) ? false : true"
                    form-floating
                ></SelectField>
            </div>
        </div> -->
    </div>
    <FadeInOut mode="out-in" :duration="300">
        <Spinner v-if="isBusy && !skinTypeAndConditionAnalysisRecords" class="spinner-black"></Spinner>
        <div v-if="skinTypeAndConditionAnalysisRecords && skinTypeAndConditionAnalysisRecords.count > 0">
            <template
                v-for="(stacarRecord, stacarIndex) in skinTypeAndConditionAnalysisRecords.items"
                :key="stacarIndex">
                <p class="heading-s fw-bold">
                    {{ dayjs(stacarRecord.fromDateTime).format('DD MMM YYYY') }} - {{ dayjs(stacarRecord.toDateTime).format('DD MMM YYYY') }}
                </p>
                <div class="grid">
                    <div
                        v-for="(assrRecord, assrIndex) in stacarRecord.analyticSkinMeasurementSectionRecords"
                        :key="assrIndex"
                        class="g-col-12 g-col-md-6 g-col-xl-4 card border">
                        <div v-if="isBusy" class="position-absolute w-100 h-100 hstack justify-content-center bg-white bg-opacity-75">
                            <Spinner class="spinner-black"></Spinner>
                        </div>
                        <div class="card-body">
                            <div class="d-flex">
                                <!-- <span class="chart-legend-color-box" :style="{ backgroundColor: AppConstants.CHART_COLOURS[assrIndex]}"></span> -->
                                <h3 class="fw-bold heading-m">{{ assrRecord.description }}</h3>
                            </div>
                            <!-- <p v-if="hasZeroSkinMeasurementValue(assrRecord.analyticSkinMeasurementValueRecords)" class="mb-0">
                                No data found.
                            </p> -->
                            <AnalyticsPercentageBar
                                v-for="asmvRecord in assrRecord.analyticSkinMeasurementValueRecords"
                                :percentage="getPercentage(assrRecord.analyticSkinMeasurementValueRecords, asmvRecord.count, stacarRecord.totalConsultations)"
                                :label="asmvRecord.skinMeasurementValueName"
                                :count="asmvRecord.count"
                                :bg-color="AppConstants.CHART_COLOURS[assrIndex]"
                            ></AnalyticsPercentageBar>
                            <AnalyticsPercentageBar
                                label="Not applicable"
                                :percentage="getNotApplicablePercentage( assrRecord.notApplicableCount, stacarRecord.totalConsultations)"
                                :count="assrRecord.notApplicableCount"
                                :bg-color="AppConstants.CHART_COLOURS[assrIndex]"
                            ></AnalyticsPercentageBar>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </FadeInOut>
    <div v-if="skinTypeAndConditionAnalysisRecords && skinTypeAndConditionAnalysisRecords.count > 1" class="mt-4 py-2">
        <Pagination
            :disabled="isBusy"
            :page-number="skinTypeAndConditionAnalysisRecords.currentPageNumber"
            :total-pages="skinTypeAndConditionAnalysisRecords.totalPages"
            :pages-per-view="3"
            :pages-per-view-md="10"
            @page-change="analyticsSkinTypeAndConditionStore.onPageChange"
            class="d-flex justify-content-center"
        ></Pagination>
    </div>
</template>

<script lang="ts" setup>
import AppConstants from '@/modules/proportal/app.constants';
import { FadeInOut } from 'vue3-transitions';
import { storeToRefs } from 'pinia';
import { useAnalyticsStore, useAnalyticsSkinTypeAndConditionStore } from '@/modules/proportal/store';
import { Spinner, Pagination } from '@/modules/core/components';
import { AnalyticsPercentageBar } from './';
import dayjs from 'dayjs';
import {
    Chart,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement,
    PointElement,
    LineElement,
    Colors,
    LinearScale,
    CategoryScale,
} from 'chart.js'

Chart.register(Title, Tooltip, Legend, ArcElement, BarElement, PointElement, LineElement, Colors, CategoryScale, LinearScale);

const analyticsSkinTypeAndConditionStore = useAnalyticsSkinTypeAndConditionStore();
const { 
    isBusy,
    skinTypeAndConditionAnalysisRecords,
    hasZeroSkinMeasurementValue,
    getPercentage,
    getNotApplicablePercentage
} = storeToRefs(analyticsSkinTypeAndConditionStore);
const { getPercentages } = storeToRefs(useAnalyticsStore());


analyticsSkinTypeAndConditionStore.load();
</script>