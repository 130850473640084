<template>
    <Offcanvas ref="offcanvasRef" class="offcanvas-skin-measurement-summary" placement="end"
        title="Skin Measurement Summary">
        <template #activator="scope">
            <slot name="activator" v-bind="scope"></slot>
        </template>

        <div class="accordion accordion-flush user-select-none">
            <div class="accordion-item">
                <Collapse>
                    <template #activator="{ onClick, isActive, a11yAttributes }">
                        <h3 class="accordion-header">
                            <button @click="onClick" v-bind="a11yAttributes"
                                class="accordion-button hstack gap-2 align-items-start"
                                :class="{ 'collapsed': !isActive }" type="button">
                                <span class="badge bg-primary">{{ sectionA.name }}</span>
                                <span>{{ sectionA.description }}</span>
                            </button>
                        </h3>
                    </template>
                    <div class="pt-3 pb-4 mb-2">
                        <div v-if="sectionASkinToneValue" class="mb-4">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="body-s fw-bold mb-0">{{ sectionASkinToneValue.name }}</h4>
                                    <!-- <p class="caption text-muted mb-0">{{ sectionASkinToneValue.description }}</p> -->
                                </div>
                            </div>
                        </div>
                        <div v-else class="mb-4">
                            <p class="heading-l text-muted">This section has not been mapped yet...</p>
                        </div>
                        <button @click="goToSection(skinMeasurementSections[0])" class="btn btn-dark btn-sm gap-2">Go to
                            Section A
                            <Icon symbol="arrow-right"></Icon>
                        </button>
                    </div>
                </Collapse>
            </div>
            <div v-for="section in skinMeasurementSections.slice(1)" class="accordion-item">
                <Collapse>
                    <template #activator="{ onClick, isActive, a11yAttributes }">
                        <h3 class="accordion-header">
                            <button @click="onClick" v-bind="a11yAttributes"
                                class="accordion-button hstack gap-2 align-items-start"
                                :class="{ 'collapsed': !isActive }" type="button">
                                <span class="badge bg-primary">{{ section.name }}</span>
                                <span>{{ section.description }}</span>
                            </button>
                        </h3>
                    </template>
                    <div class="card mb-4">
                        <div v-if="measurementListBySectionId(section.key).length != 0" class="card-body pb-1">
                            <template v-for="item in currentSection(section.key)">
                                <div class="vstack mb-3 pb-1" v-if="mappedZones(section.key, item.key).length != 0">
                                    <div class="h-100">
                                        <div class="row">
                                            <div class="col">
                                                <p class="fw-bold mb-1">
                                                    {{ item.name }}
                                                </p>
                                                <div class="d-flex flex-wrap gap-2">
                                                    <span v-if="mappedZones(currentSkinMeasurementSectionId, item.key).length === commonDataStore.faceZones.length" class="fs-s text-muted fst-italic">
                                                        All zones selected
                                                    </span>
                                                    <span v-else class="badge rounded bg-dark  bg-opacity-10 text-muted body-xs"
                                                        v-for="conditionItem in mappedZones(section.key, item.key)">
                                                        {{ conditionItem.name }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div v-else class="card-body">
                            <p class="heading-xs text-muted mb-0">This section has not been mapped yet...</p>
                        </div>
                    </div>
                    <div class="mb-4">
                        <button @click="goToSection(section)" class="btn btn-sm btn-dark gap-2 mb-2">
                            Go to Section {{ section.name }}
                            <Icon symbol="arrow-right"></Icon>
                        </button>
                    </div>
                </Collapse>
            </div>
            <p class="mt-4 mb-1">Skin code of client/patient is:</p>
            <div class="hstack gap-3 flex-wrap">
                <span v-if="sectionASkinToneValue" class="fw-bold mb-1">{{ sectionASkinToneValue.name.split('.')[0] }}</span>
                <template v-for="section in skinMeasurementSections.slice(1)">
                    <template v-for="item in currentSection(section.key)">
                        <div v-if="mappedZones(section.key, item.key).length != 0">
                            <span class="fw-bold mb-1">{{ item.name.split('.')[0] }}</span>
                        </div>
                    </template>
                </template>
            </div>

        </div>
    </Offcanvas>
</template>

<script lang="ts" setup>
import { Collapse, Offcanvas, Icon } from '@/modules/core/components';
import { usePortalConsultationSkinMappingStore, usePortalConsultationStore, useCommonDataStore } from '@/modules/proportal/store';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { SkinMeasurementSection } from '@/modules/proportal/types';
import { useRouter } from 'vue-router';

const skinMeasurementStore = usePortalConsultationSkinMappingStore();
const consultationSkinMappingStore = usePortalConsultationSkinMappingStore();
const commonDataStore = useCommonDataStore()
const consultationStore = usePortalConsultationStore();

const {
    currentSkinMeasurementSectionId,
    firstSkinMapSectionId,
    mappedZones,
    currentSection,
    measurementListBySectionId,
    skinMeasurementSections,
    currentConsultation,
    unmappedZones
} = storeToRefs(skinMeasurementStore);

const { currentConsultationId } = storeToRefs(consultationStore);

const router = useRouter();

const sectionA = computed(() => skinMeasurementSections.value[0]);
const sectionASkinToneValue = computed(() => sectionA.value.skinMeasurementValues.find(x => x.key === currentConsultation.value?.consultation?.skinToneSkinMeasurementValueId));

const offcanvasRef = ref<InstanceType<typeof Offcanvas>>()

defineExpose({
    show: () => offcanvasRef.value?.show(),
    hide: () => offcanvasRef.value?.hide()
})

const goToSection = (section: SkinMeasurementSection) => {
    // console.log(currentConsultation.value)

    if (section.key === currentSkinMeasurementSectionId.value) return;

    if (section.key === firstSkinMapSectionId.value) {
        skinMeasurementStore.$patch({
            currentSkinMeasurementSectionId: section.key
        })
        router.push({
            name: 'skin-map-section-a',
            params: {
                consultationId: currentConsultationId.value,
            }
        })
    } else {
        skinMeasurementStore.$patch({
            currentSkinMeasurementSectionId: section.key
        })
        router.push({
            name: 'skin-map-section',
            params: {
                consultationId: currentConsultationId.value,
                skinMeasurementSectionId: section.key
            }
        })
    }
}


</script>

<style lang="scss">
.offcanvas-skin-measurement-summary {
    --bs-offcanvas-width: 90%;

    @include media-breakpoint-up(md) {
        --bs-offcanvas-width: 50%;
    }
    @include media-breakpoint-up(xl) {
        --bs-offcanvas-width: 40%;
    }

    .offcanvas-title {
        font-weight: bold;
    }
}
</style>