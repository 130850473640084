<template>
    <div>
        <h2 class="heading-l fw-bold mb-4 mb-lg-3">Appointments Analysis Report</h2>
        <p class="heading-s fw-bold">
            {{ dayjs(filters.fromDateTime).format('DD MMM YYYY') }} - {{ dayjs(filters.toDateTime).format('DD MMM YYYY') }}
        </p>
    </div>
    <AnalyticsDataCard
        :is-busy="isBusy"
        :loaded="analyticsAppointmentsRecords ? true : false"
        class="flex-grow-1 card border">
        <h3 class="fw-bold heading-m mb-4">Appointment Type Frequency</h3>
        <div v-if="analyticsAppointmentsRecords && analyticsAppointmentsRecords.count > 0">
            <Pie
                v-if="analyticsAppointmentsRecords.items[0].appointmentTypeFrequencyRecords.length > 0"
                :data="{
                    labels: analyticsAppointmentsRecords.items[0].appointmentTypeFrequencyRecords.map(x => getAppointmentTypeByKey(x.appointmentTypeId)?.name),
                    datasets: [
                        {
                            label: 'Count',
                            data: analyticsAppointmentsRecords.items[0].appointmentTypeFrequencyRecords.map(x => x.count),
                            backgroundColor: analyticsAppointmentsRecords.items[0].appointmentTypeFrequencyRecords.map(x => getAppointmentTypeByKey(x.appointmentTypeId)?.backgroundColor ?? '#000'),
                        }
                    ]
                }"
                :plugins="[ChartDataLabels]"
                :options="{
                    plugins: {
                        legend: {
                            position: 'bottom'
                        },
                        datalabels: {
                            color: '#fff',
                            formatter: (value, ctx) => {
                                let sum = 0;
                                let dataArr = ctx.chart.data.datasets[0].data;
                                //@ts-ignore
                                dataArr.map(data => {
                                    //@ts-ignore
                                    sum += data;
                                });
                                //@ts-ignore
                                let percent = (value * 100) / sum;
                                //@ts-ignore
                                let percentage = percent === 0 ? '' : `${percent.toFixed(1)}%`;
                                //@ts-ignore
                                return percentage;
                            },
                            textAlign: 'center',
                            font: {
                                size: largerThanLg ? 18 : 14,
                                weight: 'bold',
                                family: 'Raleway'
                            },
                            clamp: true,
                            display: 'auto'
                        }
                    }
                }"
            ></Pie>
            <p v-else class="mb-0">
                No data found.
            </p>
        </div>
        <p v-else class="mb-0">
            No data found.
        </p>
    </AnalyticsDataCard>
</template>

<script lang="ts" setup>
// import AppConstants from '@/modules/proportal/app.constants';
import { AnalyticsDataCard } from './';
import { storeToRefs } from 'pinia';
import { useAnalyticsAppointmentsStore, useCommonDataStore } from '@/modules/proportal/store';
import { breakpointsBootstrapV5, useBreakpoints } from "@vueuse/core";
import dayjs from 'dayjs';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Colors 
} from 'chart.js'
import { Pie } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(Title, Tooltip, Legend, ArcElement, Colors);

const breakpoints = useBreakpoints(breakpointsBootstrapV5);
const largerThanLg = breakpoints.greater('lg');

const analyticsAppointmentsStore = useAnalyticsAppointmentsStore();
const { isBusy, filters, analyticsAppointmentsRecords } = storeToRefs(analyticsAppointmentsStore);

const commonDataStore = useCommonDataStore();
const { getAppointmentTypeByKey, appointmentTypes } = storeToRefs(commonDataStore);

analyticsAppointmentsStore.load()
</script>