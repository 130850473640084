<template>
    <RouterView></RouterView>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';


const router = useRouter();

router.isReady().then(() => {
    if (router.currentRoute.value.matched.length === 0) {
        router.replace({ name: 'clients' })
    }
})
</script>