<template>
    <div class="position-relative">
        <form @submit="onSubmit" class="consultant-registration-form">       
            <div class="grid gap-4 mb-5">
                <TextField
                    name="Name"
                    label="Full Name"
                    class="g-col-12 mb-0"
                    :disabled="isBusy"
                    mandatory
                ></TextField>
                <SelectField
                    @update:model-value="resetForm({ values: { ...values, TimezoneId: undefined }})"
                    name="CountryId"
                    item-value="key"
                    item-text="name"
                    label="Country/Region"
                    placeholder="Select Country/Region"
                    :items="countries"
                    class="g-col-12 mb-0"
                    :disabled="isBusy"
                    mandatory
                ></SelectField>

                <SelectField
                    name="TimezoneId"
                    item-value="key"
                    item-text="displayName"
                    label="Timezone"
                    :placeholder="!values.CountryId ? 'Please select a country/region first' : 'Select Timezone'"
                    :items="getTimezonesByCountry(values.CountryId)"
                    class="g-col-12 mb-0"
                    :disabled="isBusy"
                    mandatory
                ></SelectField>

                <TextField
                    name="BusinessName"
                    label="Registered Company Name"
                    class="g-col-12 mb-0"
                    :disabled="isBusy"
                    mandatory
                ></TextField>

                <TextField
                    name="Email"
                    type="email"
                    label="Email"
                    class="mb-0 g-col-12"
                    :disabled="isBusy"
                    mandatory
                ></TextField>

                <TextField
                    name="Password"
                    type="password"
                    label="Password"
                    class="g-col-12  mb-0"
                    :disabled="isBusy"
                    mandatory
                ></TextField>

                <TextField
                    name="ConfirmPassword"
                    type="password"
                    label="Confirm Password"
                    class="g-col-12 mb-0"
                    :disabled="isBusy"
                    mandatory
                ></TextField>
            </div>
            <div class="mb-4">
                <CheckboxField
                    class="mb-0"
                    name="isAcceptTnc"
                    :value="true"
                    :unchecked-value="false">
                    <slot name="tnc-label"></slot>
                </CheckboxField>
                <p v-if="errors.isAcceptTnc" class="caption text-danger">Please indicate that you accept the terms and conditions to continue</p>
            </div>

            <div class="vstack gap-4">
                <button type="submit" class="btn btn-primary w-100 py-3 fw-semibold d-block">Create Account</button>
                <p v-if="errorMessage" class="text-danger text-center mb-0">{{ errorMessage }}</p>
                <div class="d-flex justify-content-center">
                    <slot name="sign-in-offer"></slot>
                </div>
            </div>
        </form>
        <FadeInOut :duration="300">
            <div v-if="isBusy" class="spinner-overlay start-0 top-0">
                <Spinner></Spinner>
            </div>
        </FadeInOut>

        <EmailOtpModalField
            ref="emailOtpModalRef"
            :email="values.Email"
            name="EmailOtp"
            @submit="onOtpSubmit"
        ></EmailOtpModalField>
    </div>
</template>

<script lang="ts" setup>
import { FadeInOut } from 'vue3-transitions';
import { TextField, SelectField, CheckboxField, Spinner } from '@/modules/core/components';
import { useForm } from 'vee-validate';
import { subscriberConsultantRegistrationFormFieldsSchema } from '@/modules/proportal/components/auth/schema';
import type { SubscriberConsultantRegistrationFormFields } from '@/modules/proportal/components/auth/types';
import { ref, watch } from 'vue';
import { getErrorMessage, createFormDataFromFields } from '@/modules/core/utilities';
import { useCommonDataStore, usePortalAuthStore } from '@/modules/proportal/store';
import { storeToRefs } from 'pinia';
import { router } from '@/modules/proportal/router';
import EmailOtpModalField from './email-otp-modal-field.vue';
import { isAPIResponse } from '@/modules/core/types';
import { useConsultantOtp, useResetConsultantOtp } from './composable';

const {
    handleSubmit,
    errors,
    values,
    resetForm,
    setFieldValue,
    resetField
} = useForm<SubscriberConsultantRegistrationFormFields>({
    validationSchema: subscriberConsultantRegistrationFormFieldsSchema,
    keepValuesOnUnmount: true
});

const commonDataStore = useCommonDataStore(); 
const { countries, getTimezonesByCountry } = storeToRefs(commonDataStore);

const errorMessage = ref('');
const isBusy = ref(false);

const resetOtp = useResetConsultantOtp();

const processSubmission = async (values: SubscriberConsultantRegistrationFormFields) => {
    console.log('submit')
    isBusy.value = true;
    try {
        let formData = new FormData();
        formData = createFormDataFromFields(values);
        await usePortalAuthStore().registerSubscriberConsultant(formData);

        const route = router.resolve({
            name: 'portalSettingsBillingPlans',                
        })
        window.location.assign(`/settings/${route.href}`);
        // router.push({ name: 'portalSettingsBillingPlans' });
    } catch (error) {
        errorMessage.value = getErrorMessage(error)

        if (isAPIResponse(error)) {
            const isOtpError = error.messages.some(x => x === 'Invalid OTP');
            if (isOtpError) {
                resetField('EmailOtp');
                emailOtpModalRef.value?.show()
            }
        }
    } finally {
        isBusy.value = false;
    }
}

const onSubmit = handleSubmit(
    async (values) => {
        await processSubmission(values)
    },
    (context) => {
        const errorFields = Object.keys(context.errors);
        const isOnlyOtpNeeded = errorFields.length === 1 && errorFields[0] === 'EmailOtp';

        if (isOnlyOtpNeeded) {
            emailOtpModalRef.value?.show();
            resetField('EmailOtp')
            return;
        }

        const invalidField = document.querySelector('.is-invalid') as HTMLInputElement|HTMLSelectElement;
        if (invalidField) {
            invalidField.focus();
            window.scrollTo(0, invalidField.offsetTop - 72);
        }
    }
)

watch(() => values.CountryId, (newCountryId) => {
    if (!newCountryId) return;

    const currTzId = values.TimezoneId;
    const newCountryTzs = getTimezonesByCountry.value(newCountryId);
    
    // if existing TZ is in the list, dont do anything
    if (currTzId && !!newCountryTzs.find(x => x.key === currTzId)) return;

    setFieldValue('TimezoneId', newCountryTzs[0].key)
})

watch(() => values.Email, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        resetField('EmailOtp')
        resetOtp()
    }
})

const emailOtpModalRef = ref<InstanceType<typeof EmailOtpModalField>>();

const onOtpSubmit = () => {
    processSubmission(values)
}
</script>