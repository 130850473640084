<template>
    <div class="consultant-registration-form">
        <h1 class="fw-bold text-center my-5">{{ props.title }}</h1>
        <div class="row">
            <SubscriberRegistrationForm>
                <template #password-reset-offer>
                    <slot name="password-reset-offer"></slot>
                </template>
                <template #sign-in-offer>
                    <slot name="sign-in-offer"></slot>
                </template>
                <template #tnc-label>
                    <slot name="tnc-label"></slot>
                </template>
            </SubscriberRegistrationForm>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { SubscriberRegistrationForm } from '@/modules/proportal/components';

const props = defineProps({
    title: {
        type: String,
        default: 'Skin Masterclass PRO Registration'
    }
})
</script>