<template>
    <div ref="alertElRef" :class="['alert d-flex align-items-center', `alert-${type}`, {
        'alert-dismissible fade show': dismissible,
        'rounded-0': !rounded
    }]" role="alert" v-on="events">
        <!-- <svg class="bi flex-shrink-0 me-2" role="img" aria-label="Warning:"><use xlink:href="#exclamation-triangle-fill"></use></svg> -->
        <Icon 
            v-if="icon" 
            :symbol="icon" 
            class="bi flex-shrink-0 me-2"
        ></Icon>
        <slot></slot>
        <button 
            v-if="dismissible"
            type="button" 
            class="btn-close" 
            data-bs-dismiss="alert" 
            aria-label="Close"
        ></button>
    </div>
</template>

<script lang="ts" setup>
import Icon from './icon.vue';
import Alert from 'bootstrap/js/dist/alert';
import { onMounted, onBeforeUnmount, ref } from 'vue';

interface Props {
    rounded?: boolean;
    icon?: string;
    type?: 'primary'|'secondary'|'success'|'danger'|'warning'|'info'|'light'|'dark',
    dismissible?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    rounded: true,
    type: 'primary',
    dismissible: true
});

const emit = defineEmits(['close', 'closed']);

const alertElRef = ref<HTMLElement|null>(null);

let alertInstance: Alert;

onMounted(() => {
    if(!alertElRef.value) return;
    alertInstance = new Alert(alertElRef.value);
})

onBeforeUnmount(() => {
    if (alertInstance) {
        alertInstance.dispose();
    }
})

const events = {
    'close.bs.alert': () => {
        emit('close');
    },
    'closed.bs.alert': () => {
        emit('closed');
    },
}
</script>