<template></template>

<script lang="ts" setup>
import { PropType } from 'vue';
import { usePortalPageUrlsStore } from '@/modules/proportal/store';
import { PortalPageUrls } from '@/modules/proportal/types';

const props = defineProps({
    portalPageUrls: { type: Object as PropType<PortalPageUrls>, default: null }
})

usePortalPageUrlsStore().$patch(props);
</script>