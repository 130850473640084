<template>
    <div class="row align-items-center justify-content-between mb-4 mb-lg-3">
        <div class="col-lg-6">
            <h1 class="display-m fw-bold">Analytics</h1>
            <p>Have an in-depth look at all the metrics in your workspace.</p>
        </div>
        <div class="col-lg-6 mt-3 mt-lg-0">
            <div class="row justify-content-end">
                <div v-if="isOwner" class="col-sm-6 form-floating">
                    <AutocompleteField
                        name="consultantIds"
                        label="Filter by consultants"
                        class="mb-0"
                        :items="memberEntries"
                        item-text="name"
                        item-value="id"
                        @change="onFilter"
                        :disabled="isMembersBusy.members"
                        form-floating
                    ></AutocompleteField>
                </div>
                <div class="col-sm-6 form-floating">
                    <DatePickerField
                        name="dateRange"
                        label="Filter by date range"
                        :datePickerConfig="{
                            format: 'dd/MM/yyyy',
                            autoApply: true,
                            range: true,
                            multiCalendars: true,
                            ignoreTimeValidation: false,
                            enableTimePicker: false,
                            presetRanges: presetDateRanges,
                            menuClassName: 'analytics-datepicker-menu'
                        }"
                        @update:model-value="onFilter"
                        form-floating
                        :disabled="isMembersBusy.members"
                        class="mb-0"
                    ></DatePickerField>
                </div>
            </div>
            <!-- <div class="g-col-12 g-col-sm-6">
                <SelectField
                    name="timeInterval"
                    label="Time Interval"
                    class="mb-0"
                    :items="AppConstants.ANALYTICS_TIME_INTERVALS"
                    item-text="label"
                    item-value="value"
                    @update:model-value="onFilter"
                    :disabled="(!isBusy && skinTypeAndConditionAnalysisRecords) ? false : true"
                    form-floating
                ></SelectField>
            </div> -->
        </div>
    </div>
    <div class="pt-4 pb-5">
        <AnalyticsSummary></AnalyticsSummary>
    </div>
    <div class="pt-4 pb-5">
        <AnalyticsClients></AnalyticsClients>
    </div>
    <div class="pt-4 pb-5">
        <AnalyticsSkinTypeAndCondition></AnalyticsSkinTypeAndCondition>
    </div>
    <div class="grid pt-4 pb-5">
        <div class="d-flex flex-column g-col-12 g-col-sm-6 g-col-lg-4">
            <AnalyticsConsultations></AnalyticsConsultations>
        </div>
        <div class="d-flex flex-column g-col-12 g-col-sm-6 g-col-lg-4">
            <AnalyticsAppointments></AnalyticsAppointments>
        </div>
        <div class="d-flex flex-column g-col-12 g-col-sm-6 g-col-lg-4">
            <AnalyticsConsultationMethods></AnalyticsConsultationMethods>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { DatePickerField, SelectField, AutocompleteField } from '@/modules/core/components';
import { storeToRefs } from 'pinia';
import { nextTick, ref, computed } from 'vue';
import { useForm } from 'vee-validate';
import { 
    useAnalyticsStore,
    useAnalyticsSummaryStore,
    useAnalyticsClientsStore,
    useAnalyticsConsultationsStore,
    useAnalyticsSkinTypeAndConditionStore,
    useAnalyticsAppointmentsStore,
    usePortalAuthStore,
    usePortalSettingsMembersStore
} from '@/modules/proportal/store';
import { 
    AnalyticsSummary,
    AnalyticsClients, 
    AnalyticsSkinTypeAndCondition,
    AnalyticsConsultations,
    AnalyticsAppointments,
    AnalyticsConsultationMethods
} from './';
import { AnalyticsFilterFormFields } from './types';
import dayjs from 'dayjs';

const presetDateRanges = ref([
    {
        label: 'Today',
        range: [new Date(), dayjs().add(1, 'day').toDate()]
    },
    // {
    //     label: 'This week',
    //     range: [dayjs().startOf('week').toDate(), dayjs().startOf('week').add(7, 'days').toDate()],
    // },
    // {
    //     label: 'Last week',
    //     range: [dayjs().subtract(1, 'week').startOf('week').toDate(), dayjs().subtract(1, 'week').endOf('week').toDate()]
    // },
    {
        label: 'This month',
        range: [dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()]
    },
    {
        label: 'Last month',
        range: [dayjs().subtract(1, 'month').startOf('month').toDate(), dayjs().subtract(1, 'month').endOf('month').toDate()]
    },
    {
        label: 'Last 60 days',
        range: [dayjs().subtract(60, 'days').toDate(), new Date()]
    },
    {
        label: 'Last 90 days',
        range: [dayjs().subtract(90, 'days').toDate(), new Date()]
    },
    {
        label: 'This year',
        range: [dayjs().startOf('year').toDate(), dayjs().endOf('year').toDate()]
    },
    {
        label: 'Last year',
        range: [dayjs().subtract(1, 'year').startOf('year').toDate(), dayjs().subtract(1, 'year').endOf('year').toDate()]
    }
])

const analyticsStore = useAnalyticsStore();
const { filters } = storeToRefs(analyticsStore);

const authStore = usePortalAuthStore();
const { isOwner } = storeToRefs(authStore);

const settingsMembersStore = usePortalSettingsMembersStore();
const { members, isBusy: isMembersBusy } = storeToRefs(settingsMembersStore);

const { values } = useForm<AnalyticsFilterFormFields>({
    initialValues: {
        dateRange: [dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()],
        timeInterval: 'Lifetime',
        consultantIds: 1
    },
});

settingsMembersStore.loadMembers();

const memberEntries = computed(() => {
    if(members.value) {
        return [{
            id: 1,
            name: 'All'
        }, ...members.value.items]
    } 
    return [{
        id: 1,
        name: 'All'
    }];
})

const onFilter = () => {
    nextTick(() => {
        const { consultantIds, ...restFilters } = filters.value;
        analyticsStore.$patch({
            filters: {
                ...restFilters,
                fromDateTime: dayjs(values.dateRange[0]).format('YYYY-MM-DD'),
                toDateTime: dayjs(values.dateRange[1]).format('YYYY-MM-DD'),
                timeInterval: values.timeInterval,
                ...(values.consultantIds !== 1 ? {
                    consultantIds: [values.consultantIds]
                } : {
                    consultantIds: undefined
                }),
                pageNumber: 1,
            }
        })
        useAnalyticsSummaryStore().load()
        useAnalyticsClientsStore().load()
        useAnalyticsConsultationsStore().load();
        useAnalyticsSkinTypeAndConditionStore().load();
        useAnalyticsAppointmentsStore().load();
    })
}
</script>