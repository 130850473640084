
import { MaybeRef } from "@vueuse/core";
import { computed, ref, unref } from "vue";
import { StepItemData } from ".";

export function useSteps (valueRef: MaybeRef<{ steps: () => Array<StepItemData> }>) {
    const value = unref(valueRef);
    const steps = ref(value.steps());

    const activeStep = computed(() => steps.value.find(x => x.isActive));
    const isLastStep = computed(() => steps.value.findIndex(x => x.isActive) === steps.value.length - 1);
    const highestCompletedStep = computed(() => {
        let highestStep: StepItemData|null = null;
        for (let step of steps.value) {
            if (step.isComplete) {
                highestStep = step;
            }
        }
        return highestStep;
    });

    /**
     * Goes to the next active step relative to the current
     * active step.
     */
    const nextStep = () => {
        const currActiveIdx = steps.value.findIndex(x => x.isActive);
        if (currActiveIdx === -1) return;
        if (currActiveIdx === steps.value.length - 1) return;
        
        const nextActiveStep = steps.value[currActiveIdx + 1];
        if (nextActiveStep.isLocked) return;
        
        steps.value.forEach(x => x.isActive = false);
        nextActiveStep.isActive = true;
    };

    /**
     * Goes to the previous active step relative to the current
     * active step. 
     */
    const prevStep = () => {
        const currActiveIdx = steps.value.findIndex(x => x.isActive);
        if (currActiveIdx === -1) return;
        if (currActiveIdx === 0) return;

        const prevActiveStep = steps.value[currActiveIdx - 1];
        if (prevActiveStep.isLocked) return;
        steps.value.forEach(x => x.isActive = false);
        prevActiveStep.isActive = true;
    };

    /**
     * Changes the current active step 
     * @param stepNum the step number (NOT zero indexed)
     */
    const goToStep = (stepNum: number) => {
        const stepNumClamped = Math.min(stepNum, steps.value.length);
        const step = steps.value.find(x => x.stepNum === stepNumClamped);
        if (!step) return;
        if (step.isLocked) return;

        steps.value.forEach(x => x.isActive = false);
        step.isActive = true;
    }

    /**
     * Marks a step as complete
     * @param stepNum the step number (NOT zero indexed)
     */
    const markComplete = (stepNum?: number) => {
        const step = typeof stepNum === 'undefined'
            ? activeStep.value
            : steps.value.find(x => x.stepNum === stepNum);

        if (!step) return;
        step.isComplete = true;
    }

    /**
     * Marks all steps as complete
     */
    const markAllComplete = () => {
        steps.value.forEach(x => x.isComplete = true);
    }

    /**
     * Sets the `isLocked` value of a StepItem
     * @param stepNum the step number (NOT zero indexed)
     * @param isLocked the locked value of a step
     */
    const setStepLocked = (stepNum: number, isLocked = true) => {
        const step = steps.value.find(x => x.stepNum === stepNum);
        if (!step) return;
        step.isLocked = isLocked;
    }

    const resetSteps = () => {
        steps.value = value.steps();
    }

    return {
        nextStep,
        prevStep,
        goToStep,
        markComplete,
        steps,
        activeStep,
        isLastStep,
        highestCompletedStep,
        setStepLocked,
        resetSteps,
        markAllComplete,
    }
}