export function focusToFirstInvalidField (form: HTMLElement) {
    const invalid = form.querySelector<HTMLInputElement>('.is-invalid');
    invalid?.scrollIntoView()
    // Since scrollIntoView does not have a callback on 
    // when it is completed, we just wait lah
    setTimeout(() => invalid?.querySelector('input')?.focus(), 500)
}

/** Some Input fields dones't have input[name] e.g. 
 * -select 
 * -rich-text-editor. In this case we will need to use the label. In RchTxtEditor id must be the same as name. */
export function scrollToFirstInvalidField(errors : Object) {
    const fieldName = Object.keys(errors)[0];
    const el = document.querySelector(`input[name="${fieldName}"]`) || document.querySelector(`select[name="${fieldName}"]`) || document.querySelector(`label[for="${fieldName}"]`);
    el?.scrollIntoView();
}

export const createFormDataFromFields = (fields: Object) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(fields)) {
        if (value !== undefined) {
            formData.append(key, value)
        }
    }
    return formData;
}