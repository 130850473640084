<template>
    <div class="layout-main-content-inner">
        <router-view></router-view>
    </div>
    <Teleport v-if="showEducation || showEducationAndSummary" to="#stickyRight">
        <OffcanvasSkinMeasurementSummary v-if="showEducationAndSummary">
            <template #activator="{ show }">
                <button type="button" @click="show" class="btn btn-solero d-flex gap-2 flex-nowrap">
                    <Icon symbol="list-bulleted"></Icon>
                    <span>View Summary</span>
                </button>
            </template>
        </OffcanvasSkinMeasurementSummary>
        <ConsultationEducationStickyCta v-if="showEducation"></ConsultationEducationStickyCta>
    </Teleport>
</template>

<script lang="ts" setup>
import { router } from '@/modules/proportal/router';
import { useRoute } from 'vue-router';
import { usePortalConsultationSkinMappingStore, useConsultationProcessStore } from '@/modules/proportal/store';
import { OffcanvasSkinMeasurementSummary } from './step-skin-measurement';
import { ConsultationEducationStickyCta } from './education';
import { storeToRefs } from 'pinia';
import { computed, watch, PropType } from 'vue';
import { Icon } from '@/modules/core/components';
import { ConsultationProcess } from '../../types';

const props = defineProps({
    consultationProcess: {
        type: Object as PropType<ConsultationProcess>,
        required: true
    }
})

const route = useRoute();
const routesNamesToHaveEducationOnly = [
    'process',
    'instructions',
    'personal-health',
    'photos-step',
    'consultation-photos-step-current',
    'consultation-photos-step-previous',
]
const routeNamesToHaveEducationsAndSummary = [
    'skin-map',
    'skin-map-section-a',
    'skin-map-section',
    'skin-needs-identification',
    'skin-measurement-value-edit',
    'ingredients',
    'current-routine',
    'new-routine'
];

const consultationProcessStore = useConsultationProcessStore();

const consultationSkinMappingStore = usePortalConsultationSkinMappingStore();
const { measurementList } = storeToRefs(consultationSkinMappingStore);

const showEducation = computed(() => routesNamesToHaveEducationOnly.find(x => x === route.name) || routeNamesToHaveEducationsAndSummary.find(x => x === route.name));

const showEducationAndSummary = computed(() => routeNamesToHaveEducationsAndSummary.find(x => x === route.name));

consultationProcessStore.$patch({
    consultationProcess: props.consultationProcess
})

router
    .isReady()
    .then(() => {
        if (router.currentRoute.value.matched.length === 0) {
            router.replace({ name: 'consultation-listing' })
        }
    })

watch(() => route.name, () => {
    if (showEducationAndSummary && measurementList.value.length === 0) {
        consultationSkinMappingStore.loadMeasurementValues();
    }
})
</script>