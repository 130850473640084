<template>
    <Offcanvas
        v-if="!lgAndGreater"
        class="ats-main-navigation-more-offcanvas"
        placement="bottom"
        @show="onShow"
        @hidden="onHidden">
        <template #activator="{ show }">
            <div class="nav-item">
                <button @click="show" class="nav-link d-lg-none" type="button">
                    <Icon symbol="dots-horizontal"></Icon>
                    <span class="nav-link-text">More</span>
                </button>
            </div>
        </template>
        <div class="list-main-navigation-more-items">
            <slot></slot>
        </div>
    </Offcanvas>
    <slot v-else></slot>
</template>

<script lang="ts" setup>
import { Icon, Offcanvas } from '@/modules/core/components';
import { breakpointsBootstrapV5, useBreakpoints } from '@vueuse/core';

const breakpoints = useBreakpoints(breakpointsBootstrapV5);
const lgAndGreater = breakpoints.greater('lg');

const onShow = () => {
    document.body.classList.add('offcanvas-main-nav-more-open');
}

const onHidden = () => {
    document.body.classList.remove('offcanvas-main-nav-more-open');
}
</script>

<style lang="scss">

.ats-main-navigation-more-offcanvas {
    bottom: $proportal-primary-navbar-height;

    .offcanvas-body {
        padding: 0;
    }

    .list-main-navigation-more-items {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));

        .nav-link {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            gap: 0.25rem;
            -webkit-appearance: none;
            border: 0;
            background-color: transparent;
            color: $gray-700;

            &-text {
                font-size: .75rem;
            }

            &.active {
                color: $secondary;
            }
        }

        .icon {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}

.offcanvas-main-nav-more-open {
    .navbar-primary {
        z-index: $zindex-offcanvas + 1;
    }
}
</style>