<template>
    <form @submit.prevent="onHandleSubmit" class="login-form">
        <FadeInOut mode="out-in" :duration="300">
            <div v-if="!show2FA">
                <slot name="content"></slot>
                <TextField
                    name="email"
                    type="email"
                    label="Email"
                    autocomplete="username"
                    :disabled="isBusy"
                ></TextField>
                <TextField
                    name="password"
                    type="password"
                    label="Password"
                    autocomplete="current-password"
                    :disabled="isBusy"
                ></TextField>
                <div class="vstack gap-4">
                    <ButtonWithSpinner
                        type="submit"
                        :is-busy="isBusy"
                        :disabled="isBusy"
                        class="btn btn-primary w-100 py-3 fw-semibold d-block">
                        Sign In
                    </ButtonWithSpinner>
                    <p v-if="errorMessage" class="text-danger text-center mb-0">{{ errorMessage }}</p>
                    <a :href="resetPasswordRequestPageUrl" class="text-primary text-decoration-none d-block text-center">Forgot your password?</a>
                </div>
            </div>
            <div v-else>
                <h1>Two-Factor Authentication</h1>
                <p>Please check your inbox for your One-Time Password (OTP) to login. If you haven't received it, please check your spam or junk folder.</p>
                <div class="mt-3">
                    <TextField
                        name="otp"
                        label="One-time password (OTP)"
                        :disabled="isBusy"
                    ></TextField>
                </div>
                <div class="vstack gap-4">
                    <ButtonWithSpinner
                        type="submit"
                        :is-busy="isBusy && !isBusyResendingOtp"
                        :disabled="isBusy"
                        class="btn btn-primary w-100 py-3 fw-semibold d-block">
                        Verify
                    </ButtonWithSpinner>
                    <p v-if="errorMessage" class="text-danger text-center mb-0">{{ errorMessage }}</p>
                    <template v-if="show2FA">                        
                        <div class="d-flex flex-column flex-sm-row justify-content-center align-items-center gap-sm-2">
                            <p class="mb-0">Didn't receive the OTP?</p>
                            <button 
                                @click="resendOtp" 
                                type="button"
                                :disabled="isCountdownTimerRunning || isBusyResendingOtp"
                                :style="{
                                    '--bs-text-opacity': isCountdownTimerRunning || isBusyResendingOtp ? 0.5 : 1
                                }"
                                class="btn-reset fw-bold text-primary">
                                Resend OTP 
                                <template v-if="isCountdownTimerRunning && !isBusyResendingOtp">
                                    ({{ seconds }}s)
                                </template>
                                <Spinner v-else-if="isBusyResendingOtp" class="ms-1"></Spinner>
                            </button>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button 
                                @click="goBackToSignIn"
                                type="button"
                                :disabled="isBusy || isBusyResendingOtp"
                                class="btn-reset text-primary text-decoration-underline">
                                Sign in with a different account
                            </button>
                        </div>
                    </template>
                    <a v-else :href="resetPasswordRequestPageUrl" class="text-primary text-decoration-none d-block text-center">Forgot your password?</a>
                </div>
            </div>
        </FadeInOut>
    </form>

</template>

<script lang="ts" setup>
import { FadeInOut } from 'vue3-transitions';
import { TextField, ButtonWithSpinner, Spinner } from '@/modules/core/components';
import { useForm } from 'vee-validate';
import { loginFormFieldsSchema } from './schema';
import type { LoginFormFields } from './types';
import { ref, computed } from 'vue';
import { getErrorMessage } from '@/modules/core/utilities';
import { useCountdownTimer } from '@/modules/core/composables';
import { usePortalAuthStore, useOrganisationStore } from '@/modules/proportal/store';
import { storeToRefs } from 'pinia';
import axios from 'axios';

interface Props {
    resetPasswordRequestPageUrl: string;
    class?: string;
}

defineProps<Props>();

const { handleSubmit, setValues, values, resetForm } = useForm<LoginFormFields>({
    validationSchema: loginFormFieldsSchema,
    keepValuesOnUnmount: false
});

const portalAuthStore = usePortalAuthStore();
const { show2FA, isBusy, tempFormValues } = storeToRefs(portalAuthStore);

const organisationStore = useOrganisationStore();
const { hasActiveSubscription } = storeToRefs(organisationStore);

const url = new URL(window.location.href);
const errorMessage = ref('');
const otpCountdown = ref(0);
const isBusyResendingOtp = ref(false);

const { 
    start: startCountdownTimer,
    stop: stopCountdownTimer,
    restart: restartCountdownTimer,
    isRunning: isCountdownTimerRunning,
    seconds 
} = useCountdownTimer(30);

const resendOtp = async() => {
    if(tempFormValues.value) {
        isBusyResendingOtp.value = true;
        await portalAuthStore.signIn({
            email: tempFormValues.value.email,
            password: tempFormValues.value.password,
            rememberMe: true
        });
        isBusyResendingOtp.value = false;
        restartCountdownTimer();
    }
}

// const startCountDown = async(signIn = false) => {
//     if(otpCountdown.value > 0) {
//         setTimeout(() => {
//             otpCountdown.value -= 1;
//             if(otpCountdown.value === 0) {
//                 return;
//             }
//             startCountDown();
//         }, 1000);
//     } else if (otpCountdown.value === 0) {
//         errorMessage.value = '';
//         otpCountdown.value = 30;
//         if(signIn && tempFormValues.value) {
//             isBusyResendingOtp.value = true;
//             await portalAuthStore.signIn({
//                 email: tempFormValues.value.email,
//                 password: tempFormValues.value.password,
//                 rememberMe: true
//             });
//             isBusyResendingOtp.value = false;
//         }
//         startCountDown();
//     }
// }

// const countDowning = computed(() => otpCountdown.value > 0);

const tempEmail = ref('');

const onHandleSubmit = () => {
    if(show2FA.value) {
        setValues({
            email: tempFormValues.value?.email,
            password: tempFormValues.value?.password
        })
    }
    onSubmit();
}

const onSubmit = handleSubmit(
    async (values) => {
        errorMessage.value = '';
        const isDifferentAccount = values.email !== tempFormValues.value?.email;
        try {
            if(!show2FA.value) {
                await portalAuthStore.signIn(values);
                if(show2FA.value) {
                    // if user has attempted to login before, tempFormValues should have something
                    if(isDifferentAccount) {
                        restartCountdownTimer();
                    } else {
                        if(!isCountdownTimerRunning.value) {
                            restartCountdownTimer();
                        }
                    }
                }
            } else {
                if(!tempFormValues.value) return;
                await portalAuthStore.signIn({
                    ...tempFormValues.value,
                    otp: values.otp
                });
            }
            if(show2FA.value && !values.otp) return;

            if(hasActiveSubscription.value) {
                const ref = url.searchParams.get('ref');
                if (ref) {
                    const redirectUrl = new URL(ref);
                    redirectUrl.hash = window.location.hash;
                    window.location.assign(redirectUrl.toString());
                    return;
                }
                window.location.assign('/');
            } else {
                window.location.assign('/settings');
            }

        } catch (error) {
            if(axios.isAxiosError(error)) {
                if(error.response?.status === 401) {
                    // If OTP is invalid
                    if(show2FA.value) {
                        errorMessage.value = 'The OTP you entered is invalid. Please check the code and try again.';
                    } else {
                        errorMessage.value = 'Incorrect login credentials. Please try again.'
                    }
                } else if(error.response?.status === 403) {
                    show2FA.value = true;
                } else if(error.response?.status === 412) {
                    // When unapproved licensee/enterprise users try to login
                    errorMessage.value = 'Your account is under review. Access will be provided upon approval.';
                }
            } else {
                errorMessage.value = getErrorMessage(error)
            }
        } finally {
            isBusy.value = false;
        }
    }
)

const goBackToSignIn = () => {
    portalAuthStore.resetWhenGoBackToSignIn();
    errorMessage.value = '';
    resetForm();
}
</script>

<style lang="scss">
.login-form label {
    font-weight: 600;
}

.login-form label[for=txtRememberMe] {
    font-weight: 400;
}

.form-check-input:checked {
    background-color: transparent;
}
</style>