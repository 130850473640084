<template>
    <template v-if="!isSubmitted">
        <h1 class="fw-bold text-center mt-5 mb-4">Reset Password</h1>
        <p class="text-center">Enter a new password for <span class="fw-bold">{{ searchParams.get('email') }}</span>.</p>
        <form @submit="onSubmit" class="mt-5">
            <TextField
                type="password"
                name="newPassword"
                label="New Password"
                :disabled="isSubmitting"
                mandatory
            ></TextField>
            <TextField
                type="password"
                name="confirmNewPassword"
                label="Confirm New Password"
                :disabled="isSubmitting"
                mandatory
            ></TextField>
            <p v-if="errorMessage" class="text-danger text-center">{{ errorMessage }}</p>
            <div class="d-flex justify-content-center" :class="{ 'pt-3': !errorMessage }"> 
                <ButtonWithSpinner 
                    type="submit"
                    :is-busy="isSubmitting"
                    :disabled="isSubmitting"
                    class="btn-primary w-100 py-3 fw-semibold d-block">
                    Reset password
                </ButtonWithSpinner>
            </div>
        </form>
        <div class="d-flex justify-content-center mt-3">
            <a :href="loginPageUrl" class="text-primary text-decoration-none d-block text-center">Sign In Instead</a>
        </div>
    </template>
    <div v-else>
        <h1 class="fw-bold text-center mt-5 mb-4">Your password has been reset successfully.</h1>
        <p class="text-center">You can proceed to sign in now.</p>
        <div class="d-flex justify-content-center"> 
            <a :href="loginPageUrl" class="btn btn-primary w-100 py-3 fw-semibold d-block">Sign in</a>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { TextField, ButtonWithSpinner } from '@/modules/core/components';
import { useForm } from 'vee-validate';
import { completeResetPasswordRequestFormFieldsSchema } from './schema';
import { CompleteResetPasswordRequestFormFields } from './types';
import { getErrorMessage } from '@/modules/core/utilities';
import { usePortalAuthStore } from '@/modules/proportal/store';
import { ref, watch } from 'vue';

defineProps({
    loginPageUrl: String
})
const searchParams = new URLSearchParams(window.location.search);

const {
    handleSubmit,
    isSubmitting,
    values,
    resetForm
} = useForm<CompleteResetPasswordRequestFormFields>({
    validationSchema: completeResetPasswordRequestFormFieldsSchema,
    initialValues: {
        email: searchParams.get('email') ?? '',
        resetToken: searchParams.get('token') ?? '',
        newPassword: '',
        confirmNewPassword: ''
    }
});

const errorMessage = ref('');
const isSubmitted = ref(false);

watch(values, (newValues, oldValues) => {
    errorMessage.value = '';
})

const onSubmit = handleSubmit(
    async(values) => {
        try {
            const { confirmNewPassword, ...restValues } = values;
            await usePortalAuthStore().completePasswordRequest(restValues);
            isSubmitted.value = true;
        } catch(error) {
            errorMessage.value = getErrorMessage(error);
        }
    }
)
</script>