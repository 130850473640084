<template>
    <RouterLink
        v-if="backButtonRoute?.name"
        class="btn btn-solero btn-consultation-education-sticky-cta hstack gap-2"
        :to="{ name: `${backButtonRoute.name?.toString()}-educationOffcanvas` }">
        <Icon symbol="education"></Icon>
        <span>Education</span>
    </RouterLink>
</template>

<script setup lang="ts">
import { Icon } from '@/modules/core/components';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const backButtonRoute = computed(() => {
    return route.matched.find(x => x.name === route.meta['educationOffcanvasStickyRouteName']);
})

</script>
