<template>
    <div>
        <FadeInOut mode="out-in" :duration="300">
            <div v-if="isBusy" class="position-absolute w-100 h-100 hstack justify-content-center bg-white bg-opacity-75">
                <Spinner class="spinner-black"></Spinner>
            </div>
        </FadeInOut>
        <FadeInOut mode="out-in" :duration="300">
            <div class="card-body" :class="{ invisble: !loaded }">
                <div v-if="iconName" class="hstack justify-content-between">
                    <h3 class="body-s fw-semibold mb-0">{{ title }}</h3> 
                    <Icon :symbol="iconName" size="xl"></Icon>
                </div>
                <slot>
                    <h3 class="heading-xl fw-bold mt-4">{{ value }}</h3> 
                    <div class="hstack">
                        <div 
                            v-if="percentage !== undefined && percentage !== null"
                            class="hstack">
                            <Icon 
                                :symbol="percentage > 0 ? 'trend-up' : percentage < 0 ? 'trend-down' : 'trend-equal'"
                                :class="{ 
                                    'text-success': percentage > 0,
                                    'text-warning': percentage === 0,
                                    'text-danger': percentage < 0
                                }"
                                size="l"
                            ></Icon>
                            <span 
                                :class="{ 
                                    'text-success': percentage > 0,
                                    'text-warning': percentage === 0,
                                    'text-danger': percentage < 0
                                }"
                                class="fw-bold heading-s ms-1 lh-sm">
                                {{ Math.round(percentage) }}%
                            </span>
                        </div>
                        <template v-if="comparingDuration">                            
                            <span class="ms-2 fw-semibold text-gray-600 subheading">
                                vs {{ humanizedDuration }}
                            </span>
                        </template>
                    </div>
                </slot> 
            </div>
        </FadeInOut>
    </div>
</template>

<script lang="ts" setup>
import { FadeInOut } from 'vue3-transitions';
import { Spinner, Icon } from '@/modules/core/components';
import { computed } from 'vue';
import dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration';
import RelativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(Duration);
dayjs.extend(RelativeTime);

const props = defineProps({
    isBusy: Boolean,
    loaded: Boolean,
    iconName: String,
    percentage: Number,
    title: String,
    value: String,
    comparingDuration: Number
})

const humanizedDuration = computed(() => {
    if(!props.comparingDuration) return;
    const days = dayjs.duration(props.comparingDuration, 'days').asDays();

    if(days === 1) {
        return `${days} day ago`;
    } else {
        return `${days } days ago`;
    }
})

</script>