<template>
    <div class="consultant-registration-form">
        <h1 class="fw-bold text-center my-5">{{ props.title }}</h1>
        <div v-if="!hasFinishedAllSteps" class="row">
            <div class="col-12 mx-auto">
                <!-- <Steps class="mb-5">
                    <StepItem
                        v-for="step in steps"
                        :step="step.stepNum"
                        :active="step.isActive"
                        :completed="step.isComplete"
                        :locked="step.isLocked"
                        :go-to-step="goToStep">
                        {{ step.stepLabel }}
                    </StepItem>
                </Steps> -->
            </div>
        </div>
        <div class="row">
            <KeepAlive>
                <ConsultantEnterpriseRegistrationForm v-if="steps[0].isActive" @done="onStep1Done">
                    <template #password-reset-offer>
                        <slot name="password-reset-offer"></slot>
                    </template>
                    <template #sign-in-offer>
                        <slot name="sign-in-offer"></slot>
                    </template>
                </ConsultantEnterpriseRegistrationForm>
                <RegistrationAuthForm 
                    v-else-if="steps[1].isActive" 
                    @done="onStep2Done"
                    mode="enterprise">
                    <template #tnc-label>
                        <slot name="tnc-label"></slot>
                    </template>
                </RegistrationAuthForm>
                <div v-else-if="steps[2].isActive">
                    <slot name="under-review-content"></slot>
                </div>
            </KeepAlive>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ConsultantEnterpriseRegistrationForm, RegistrationAuthForm } from '@/modules/proportal/components';
import { Steps, StepItem, useSteps } from '@/modules/core/components';
import { usePortalAuthStore } from '@/modules/proportal/store';
import type { EnterpriseConsultantRegistrationFormFields } from './types';
import { ref } from 'vue';

const portalAuthStore = usePortalAuthStore();

const hasFinishedAllSteps = ref(false);

const props = defineProps({
    title: {
        type: String,
        default: 'Skin Masterclass PRO Registration'
    }
})

const { 
    steps, 
    nextStep, 
    goToStep,
    markComplete,
    setStepLocked
} = useSteps({
    steps: () => [
        { stepNum: 1, isActive: !false, isComplete: false, isLocked: false, stepLabel: 'Registration' },
        { stepNum: 2, isActive: false, isComplete: false, isLocked: true, stepLabel: 'Authentication' },
        { stepNum: 3, isActive: false, isComplete: false, isLocked: true, stepLabel: 'Review' },
    ]
})

const onStep1Done = (data: EnterpriseConsultantRegistrationFormFields) => {
    portalAuthStore.enterpriseConsultantRegistrationData = data;
    markComplete(1)
    setStepLocked(2, false);
    nextStep();
}

const onStep2Done = () => {
    markComplete(2);
    setStepLocked(1);
    setStepLocked(2);
    setStepLocked(3, false);
    nextStep();
    hasFinishedAllSteps.value = true;
}

</script>

<style lang="scss">
.consultant-registration-form {
    .steps {
        .step-item {
            align-items: center;
            &-label { justify-content: center; }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                width: 57.5%;
                transform: translateX(125%);
            }

            @include media-breakpoint-up (md) {
                &::after {
                    width: 80%;
                    transform: translateX(75%);
                }
            }
        }
    }
}
</style>