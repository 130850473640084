<template>
    <div class="position-relative">
        <div class="hstack gap-2 mb-3">
            <Icon symbol="lock" size="l" class="text-secondary"></Icon>
            <span class="heading-l fw-bold text-gray-700">Authentication</span>
        </div>
        <p class="text-muted">Please enter your login details you will use to login to the portal.</p>

        <form @submit="handleEmailPwdFormSubmit" ref="formRef">
            <div class="grid gap-4">
                <TextField
                    name="Email"
                    type="email"
                    label="Email"
                    class="g-col-12 g-col-sm-6 mb-0"
                    :disabled="isBusy"
                    mandatory
                ></TextField>
                <TextField
                    name="Password"
                    type="password"
                    label="Password"
                    class="g-col-12 g-col-sm-6 mb-0"
                    :disabled="isBusy"
                    mandatory
                ></TextField>
            </div>

            <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>

            <div class="mb-4">
                <CheckboxField
                    class="mb-0 mt-4"
                    name="isAcceptTnc"
                    :value="true"
                    :unchecked-value="false">
                    <slot name="tnc-label"></slot>
                </CheckboxField>
                <p v-if="errors.isAcceptTnc" class="caption text-danger">Please indicate that you accept the terms and conditions to continue</p>
            </div>

            <div class="d-grid">
                <button type="submit" class="btn btn-secondary">Continue</button>
            </div>
        </form>
        <FadeInOut :duration="300">
            <div v-if="authInProgress" class="spinner-overlay start-0 top-0">
                <Spinner></Spinner>
            </div>
        </FadeInOut>
    </div>
</template>

<script lang="ts" setup>
import { TextField, CheckboxField } from '@/modules/core/components';
import { Icon, Spinner } from '@/modules/core/components';
import { usePortalAuthStore } from '@/modules/proportal/store';
import { getErrorMessage, scrollToFirstInvalidField } from '@/modules/core/utilities';
import { useForm } from 'vee-validate';
import { ref } from 'vue';
import { FadeInOut } from 'vue3-transitions';
import { consultantRegistrationAuthFormFieldsSchema } from '@/modules/proportal/components/auth/schema';
import type { 
    ConsultantRegistrationAuthFormFields, 
    SubscriberConsultantRegistrationFormFields,    
    EnterpriseConsultantRegistrationFormFields
} from '@/modules/proportal/components/auth/types';
import { storeToRefs } from 'pinia';

interface Props {
    mode: 'enterprise'|'subscriber'
}

const props = defineProps<Props>();

const emit = defineEmits(['done']);
const portalAuthStore = usePortalAuthStore();
const { subscriberConsultantRegistrationData, enterpriseConsultantRegistrationData } = storeToRefs(portalAuthStore);

const authInProgress = ref(false);
const isBusy = ref(false);
const errorMessage = ref('');

const { handleSubmit, errors } = useForm<ConsultantRegistrationAuthFormFields>({
    validationSchema: consultantRegistrationAuthFormFieldsSchema,
})

const formRef = ref<HTMLElement|null>(null);

const serialize = (values: SubscriberConsultantRegistrationFormFields|EnterpriseConsultantRegistrationFormFields) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(values)) {
        if (value !== undefined) {
            formData.append(key, value)
        }
    }
    return formData;
}

const handleEmailPwdFormSubmit = handleSubmit(
    async values => {
        errorMessage.value = "";

        try {
            authInProgress.value = true;
            let formData: FormData = new FormData();
            
            if(props.mode === 'enterprise' && enterpriseConsultantRegistrationData.value) {
                formData = serialize(enterpriseConsultantRegistrationData.value);
                formData.set('Email', values.Email);
                formData.set('Password', values.Password);
                await portalAuthStore.registerEnterpriseConsultant(formData);
            } else if (props.mode === 'subscriber' && subscriberConsultantRegistrationData.value) {
                formData = serialize(subscriberConsultantRegistrationData.value);
                formData.set('Email', values.Email);
                formData.set('Password', values.Password);
                await portalAuthStore.registerSubscriberConsultant(formData);
            }
            
            authInProgress.value = false;
            emit('done');
        } catch (error) {
            errorMessage.value = getErrorMessage(error);
        } finally {
            authInProgress.value = false;
        }
    },
    () => formRef.value && scrollToFirstInvalidField(formRef.value)
)

</script>