import AppConstants from '@/modules/proportal/app.constants';
import { usePortalAuthStore } from '@/modules/proportal/store/auth';
import axios from 'axios';

export default function configureAxios () {
    const authStore = usePortalAuthStore();

    axios.interceptors.request.use(
		function (config) {
			let headers: Record<string, string> = {};

			config.paramsSerializer = {
				indexes: null
			}
			// if (config.paramsSerializer) {
			// 	config.paramsSerializer.encode = (params) => qs.stringify(params, { arrayFormat: 'repeat' })
			// }

            if (authStore.auth.accessToken) {
				headers[AppConstants.AUTH_TOKEN_HTTP_HEADER_NAME] = `Bearer ${authStore.auth.accessToken}`;
            }

			// const culture = getCurrentLanguageIsoCode();
			// if (culture) {
			// 	headers[AppConstants.CULTURE_HTTP_HEADER_NAME] = culture;
			// }

			config.headers = {
				...config.headers,
				...headers
			}
            
            return config;
        }
    )

    // only when logout refresh and remove auth
	// when api return 401, remove auth
	axios.interceptors.response.use(
		(response) => {
			return response
		},
		error => {
			if (!error.response) return Promise.reject(error);
			
			if (axios.isAxiosError(error)) {
				const whitelist = AppConstants.AUTH_INTERCEPTOR_WHITELIST;
				const requestUrl = error.request.responseURL;
				if (whitelist.some(x => requestUrl.includes(x))) {
					return Promise.reject(error);
				}

				const { status } = error.response;
				if (status === 401) {
					// authStore.logout()
					if (!error.config?.suppressAuthCheckRedirect) {
						window.location.href = `${window.location.origin}${AppConstants.SIGNIN_PAGE_URL}?ref=${encodeURIComponent(window.location.href)}`;
					}
				}
			}
			
			return Promise.reject(error);
		}
	)
}