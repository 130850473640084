<template>
    <template v-if="!isSubmitted">
        <h1 class="fw-bold text-center mt-5 mb-4">Forgot Your Password</h1>
        <p class="text-center">Enter your email, and we'll send you instructions on how to reset your password.</p>
        <form @submit="onSubmit" class="mt-5">
            <TextField
                type="email"
                name="email"
                label="Email address"
                class="mb-3"
                :disabled="isSubmitting"
                mandatory
            ></TextField>
            <p v-if="errorMessage" class="text-danger text-center">{{ errorMessage }}</p>
            <div class="d-flex justify-content-center" :class="{ 'pt-3': !errorMessage }"> 
                <ButtonWithSpinner 
                    type="submit"
                    :is-busy="isSubmitting"
                    :disabled="isSubmitting"
                    class="btn-primary w-100 py-3 fw-semibold d-block">
                    Send me reset instructions</ButtonWithSpinner>
            </div>
        </form>
    </template>
    <div v-else>
        <h1 class="fw-bold text-center mt-5 mb-4">Your password reset instructions has been sent</h1>
        <p class="text-center">We have sent a password reset instructions to your email address: <span class="fw-bold">{{ values.email }}</span></p>
        <div class="d-flex justify-content-center"> 
            <button
                @click="goBack"
                type="button"
                class="btn btn-primary w-100 py-3 fw-semibold d-block">
                Try with another email</button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { TextField, ButtonWithSpinner } from '@/modules/core/components';
import { useForm } from 'vee-validate';
import { resetPasswordRequestFormFieldsSchema } from './schema';
import { ResetPasswordRequestFormFields } from './types';
import { getErrorMessage } from '@/modules/core/utilities';
import { usePortalAuthStore } from '@/modules/proportal/store';
import { useNotifications } from '@/modules/core/composables';
import { ref, watch } from 'vue';

const {
    handleSubmit,
    isSubmitting,
    values,
    resetForm
} = useForm<ResetPasswordRequestFormFields>({
    validationSchema: resetPasswordRequestFormFieldsSchema
});

const notifications = useNotifications();
const errorMessage = ref('');
const isSubmitted = ref(false);

watch(values, (newEmail, oldEmail) => {
    errorMessage.value = '';
})

const onSubmit = handleSubmit(
    async(values) => {
        try {
            await usePortalAuthStore().createResetPasswordRequest(values);
            isSubmitted.value = true;
        } catch(error) {
            errorMessage.value = getErrorMessage(error);
        }
    }
)

const goBack = () => {
    resetForm();
    isSubmitted.value = false;
}
</script>