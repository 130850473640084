<template>
    <div class="layout-main-content-inner">
        <router-view></router-view>
    </div>
</template>

<script lang="ts" setup>
import { router } from '../../router';
import { useOrganisationStore } from '@/modules/proportal/store';
import { storeToRefs } from 'pinia'; 

const organisationStore = useOrganisationStore();
const { hasActiveSubscription, hasAnySubscription } = storeToRefs(organisationStore); 

router.isReady().then(() => {
    if (router.currentRoute.value.matched.length === 0) {
        if(hasActiveSubscription.value) {
            router.replace({ name: 'accountDetails' })
        } else if (!hasActiveSubscription.value && hasAnySubscription.value) {
            router.replace({ name: 'portalSettingsBilling' })
        } else {
            router.replace({ name: 'portalSettingsBilling' })
        }
    }
})
</script>

<style lang="scss" scoped>

.user-border {
    border-bottom: 1px solid $gray-300;

    @media (min-width: 992px) {
        border-bottom: none;
        border-right: 1px solid $gray-300;
    }
}

.nav-link {
    &:not(.active) {
        &:hover {
            background: $gray-100;
        }
    }
}
</style>