import { AxiosResponse } from "axios";

export interface APIResponse<T = void> {
    data: T;
    isSuccess: boolean;
    messages: Array<string>;
}

export function isAPIResponse (object: any): object is APIResponse {
    if (!object) return false;
    if (typeof object === 'string') return false;
    return 'data' in object && 'isSuccess' in object && 'messages' in object;
}

export interface APIErrorResponse {
    errors: Record<string, Array<string>>;
    status: number;
    title: string;
    traceId: string;
    type: string;
}

export type AxiosResponseWrapped<T> = AxiosResponse<APIResponse<T>>;