<template>
    <RouterView></RouterView>
</template>

<script setup lang="ts">
import { router } from '@/modules/proportal/router';

router
    .isReady()
    .then(() => {
        if (router.currentRoute.value.matched.length === 0) {
            router.replace({ name: 'helpSupport-home' })
        }
    })
</script>

<style scoped>

</style>