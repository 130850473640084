<template>
    <div class="g-col-12 g-col-md-4 pricing-card" :class="{
        'is-recommended': plan.isRecommended
    }">
        <div class="justify-content-center mb-3" :class="{
            'is-not-recommended': !plan.isRecommended,
            'd-flex': plan.isRecommended
        }">
            <span class="badge bg-success fw-normal p-2">
                Best Deal
            </span>
        </div>
        <h3 class="heading-l text-center text-primary fw-bolder price-plan-name">
            {{ plan.name }}
        </h3>
        <p class="fw-bolder text-center d-flex justify-content-center align-items-center mb-0 price">
            <span>{{ priceToShow }} </span>
            <span class="body-s ms-2">per {{ interval === 'month' ? 'month' : 'year' }}</span>
        </p>
        <a :href="portalPageUrls.subscriberRegistration" class="btn btn-primary w-100 py-2 py-lg-3 fw-semibold mt-4 text-dark">
            Subscribe
        </a>
        <p class="mt-4">You'll get:</p>
        <ul class="mb-0">
            <li>{{ plan.maximumClient }} Clients</li>
            <li>{{ plan.maximumConsultant }} Consultants</li>
            <li>Downloadable report with company logo</li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
import type { SubscriptionPlanInterval } from '@/modules/proportal/types';
import { SubscriptionPlan } from '@/modules/proportal/types';
import { formatSubscriptionPlanPrice } from '@/modules/core/utilities';
import { usePortalPageUrlsStore } from '@/modules/proportal/store';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

interface Props {
    plan: SubscriptionPlan;
    interval: SubscriptionPlanInterval;
}

const props = defineProps<Props>();

const { portalPageUrls } = storeToRefs(usePortalPageUrlsStore());

const priceToShow = computed(() => {
    const price = props.plan.prices.find(x => x.content.interval.name === props.interval);
    if(price) {
        return formatSubscriptionPlanPrice(price.content.amount, price.content.currency.code);
    }
    return formatSubscriptionPlanPrice(0, 'EUR');
})
</script>

<style lang="scss" scoped>
.pricing-card {
    padding: 1.25rem;
    border-radius: 20px;
    @include media-breakpoint-up(lg) {
        padding: 2rem;
    }
    &.is-recommended {
        background-color: $white;
        border: 1px solid #E2E5F1;
    }
    .is-not-recommended {
        display: none;
        @include media-breakpoint-up(md) {
            display: flex;
            opacity: 0;
        }
    }
    .price-plan-name {
        @include font-size(18px !important);
        line-height: 132%;
        @include media-breakpoint-up(lg) {
            @include font-size(24px !important);
        }
    }
    .price {
        line-height: 124%;
        @include font-size(40px !important);
        @include media-breakpoint-up(md) {
            @include font-size(28px !important);
        }
        @include media-breakpoint-up(lg) {
            @include font-size(32px !important);
        }
    }
}
</style>