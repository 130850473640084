<template>
    <div>
        <h2 class="heading-l fw-bold mb-4 mb-lg-3">Consultation Analysis Report</h2>
        <p class="heading-s fw-bold">
            {{ dayjs(filters.fromDateTime).format('DD MMM YYYY') }} - {{ dayjs(filters.toDateTime).format('DD MMM YYYY') }}
        </p>
    </div>
    <AnalyticsDataCard
        :is-busy="isBusy"
        :loaded="analyticsConsultationsRecords ? true : false"
        class="flex-grow-1 card border">
        <h3 class="heading-m mb-4 fw-bold">Order Payment Status</h3>
        <div v-if="consultationOrderHistoryPaymentStatusRecords">
            <template v-if="consultationOrderHistoryPaymentStatusRecords.count > 0">
                <Pie
                    v-if="!hasZeroPaymentStatuses"
                    :data="{
                        labels: consultationOrderHistoryPaymentStatusRecords.items[0].consultationOrderHistoryPaymentStatusFrequencyRecords.map(x => x.status),
                        datasets: [
                            {
                                label: 'Count',
                                data: consultationOrderHistoryPaymentStatusRecords.items[0].consultationOrderHistoryPaymentStatusFrequencyRecords.map(x => x.count),
                                backgroundColor: AppConstants.CHART_COLOURS,
                            }
                        ]
                    }"
                    :plugins="[ChartDataLabels]"
                    :options="{
                        plugins: {
                            legend: {
                                position: 'bottom'
                            },
                            datalabels: {
                                color: '#fff',
                                formatter: (value, ctx) => {
                                    let sum = 0;
                                    let dataArr = ctx.chart.data.datasets[0].data;
                                    //@ts-ignore
                                    dataArr.map(data => {
                                        //@ts-ignore
                                        sum += data;
                                    });
                                    //@ts-ignore
                                    let percent = (value * 100) / sum;
                                    //@ts-ignore
                                    let percentage = percent === 0 ? '' : `${percent.toFixed(1)}%`;
                                    //@ts-ignore
                                    return percentage;
                                },
                                textAlign: 'center',
                                font: {
                                    size: largerThanLg ? 18 : 14,
                                    weight: 'bold',
                                    family: 'Raleway'
                                },
                                clamp: true,
                                display: 'auto'
                            }
                        }
                    }"
                ></Pie>
                <p v-else class="mb-0">
                    No data found.
                </p>
            </template>
        </div>
        <p v-else class="mb-0">
            No data found.
        </p>
    </AnalyticsDataCard>
    <!-- <AnalyticsDataCard
        :is-busy="isBusy"
        :loaded="analyticsConsultationsRecords ? true : false"
        class="flex-grow-1 card border">
        <div v-if="consultationStatusAnalysisRecords">
            <h3 class="heading-m mb-4 fw-bold">Consultations Status Frequency</h3>
            <template v-if="consultationStatusAnalysisRecords.count > 0">
                <Pie
                    v-if="consultationStatusAnalysisRecords.items[0].total > 0"
                    :data="{
                        labels: consultationStatusAnalysisRecords.items[0].consultationStatusFrequencyRecords.map(x => getConsultationStatusByKey(x.consultationStatusId)?.name),
                        datasets: [
                            {
                                label: 'Count',
                                data: consultationStatusAnalysisRecords.items[0].consultationStatusFrequencyRecords.map(x => x.count),
                                backgroundColor: AppConstants.CHART_COLOURS,
                            }
                        ]
                    }"
                    :plugins="[ChartDataLabels]"
                    :options="{
                        plugins: {
                            legend: {
                                position: 'bottom'
                            },
                            datalabels: {
                                color: '#fff',
                                formatter: (value, ctx) => {
                                    let sum = 0;
                                    let dataArr = ctx.chart.data.datasets[0].data;
                                    //@ts-ignore
                                    dataArr.map(data => {
                                        //@ts-ignore
                                        sum += data;
                                    });
                                    //@ts-ignore
                                    let percentage = `${Math.round(value * 100 / sum)}%`;
                                    //@ts-ignore
                                    return percentage;
                                },
                                textAlign: 'center',
                                font: {
                                    size: 14,
                                    weight: 'bold',
                                    family: 'Raleway'
                                },
                                clamp: true,
                                display: 'auto'
                            }
                        }
                    }"
                ></Pie>
                <p v-else class="mb-0">
                    No data found.
                </p>
            </template>
        </div>
    </AnalyticsDataCard> -->
</template>

<script lang="ts" setup>
import AppConstants from '@/modules/proportal/app.constants';
import { AnalyticsDataCard } from './';
import { storeToRefs } from 'pinia';
import { useAnalyticsConsultationsStore, useCommonDataStore } from '@/modules/proportal/store';
import { breakpointsBootstrapV5, useBreakpoints } from "@vueuse/core";
import dayjs from 'dayjs';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    Colors 
} from 'chart.js'
import { Pie } from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(Title, Tooltip, Legend, ArcElement, Colors);

const breakpoints = useBreakpoints(breakpointsBootstrapV5);
const largerThanLg = breakpoints.greater('lg');

const analyticsConsultationsStore = useAnalyticsConsultationsStore();
const { 
    isBusy, 
    filters, 
    analyticsConsultationsRecords,
    hasZeroPaymentStatuses,
    // consultationStatusAnalysisRecords,
    consultationOrderHistoryPaymentStatusRecords
} = storeToRefs(analyticsConsultationsStore);

// const commonDataStore= useCommonDataStore();
// const { getConsultationStatusByKey } = storeToRefs(commonDataStore);

analyticsConsultationsStore.load()
</script>